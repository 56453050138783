@charset "utf-8";

/*! 999.9 v1.0.01uwkg */

@keyframes iv-ln-hr {
  0% {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}

@keyframes iv-info-hr {
  0% {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes iv {
  0% {
    opacity: 0;
    transform: translate3d(0, 1.25rem, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes iv-2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes iv-i-0 {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes iv-i-1 {
  0% {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes iv-i-2 {
  0% {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes gmbo {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes gmbc {
  0% {
    transform: none;
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes gmo {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes gmc {
  0% {
    transform: none;
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes vbs4 {
  0% {
    margin: .625rem .0625rem 3.75rem 0;
    height: 0;
  }

  40%, 60% {
    margin: .625rem .0625rem 0 0;
    height: 3.75rem;
  }

  to {
    margin: 4.375rem .0625rem 0 0;
    height: 0;
  }
}

@keyframes vbs0 {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes vbs1 {
  0% {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes vbs2 {
  0% {
    transform: none;
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes main-scroll {
  0% {
    margin: .625rem .0625rem 3.75rem 0;
    height: 0;
  }

  40%, 60% {
    margin: .625rem .0625rem 0 0;
    height: 3.75rem;
  }

  to {
    margin: 4.375rem .0625rem 0 0;
    height: 0;
  }
}

@keyframes iv-hl-hr {
  0% {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes iv-hl-img {
  0% {
    opacity: 0;
    transform: translate3d(0, .625rem, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes vbs3 {
  0% {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: none;
  }
}

::after, ::before {
  box-sizing: border-box;
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  cursor: default;
  line-height: 1.5;
  -moz-tab-size: 4;
       tab-size: 4;
  -webkit-tap-highlight-color: transparent;
      -ms-text-size-adjust: 100%;
  word-break: break-word;
  font-feature-settings: 'palt' 1;
  height: 100%;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background-color: #fff;
  color: #000;
  font-family: 'Hiragino Sans',
                 'ヒラギノ角ゴ ProN W3',
                 "Hiragino Kaku Gothic ProN",
                 "メイリオ",
                 Meiryo,
                 "ＭＳ Ｐゴシック",
                 "MS PGothic",
                 sans-serif;
  font-weight: 300;
  font-size: 4.266666666666667vw;
}

body, button, dl dl, dl ol, dl ul, input, ol dl, ol ol, ol ul, select, ul dl,
ul ol, ul ul {
  margin: 0;
}

hr {
  color: inherit;
  overflow: visible;
  height: 1px;
  border: 0;
  border-top: 1px solid #dddddb;
}

details, main {
  display: block;
}

nav ol, nav ul {
  list-style: none;
  padding: 0;
}

nav li::before {
  content: "\200B";
}

pre {
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

a {
  background-color: transparent;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

iframe, img {
  border-style: none;
}

svg:not([fill]) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #a0a0a0;
  padding: .35em .75em .625em;
}

button, input {
  overflow: visible;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

button, select {
  text-transform: none;
}

textarea {
  margin: 0;
  overflow: auto;
  resize: block;
}

[type=checkbox], [type=radio] {
  padding: 0;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

dialog {
  background-color: #fff;
  border: solid;
  color: #000;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

summary {
  display: list-item;
}

[tabindex], a, area, button, input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
}

[hidden], template {
  display: none;
}

[aria-busy=true] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled=true], [disabled] {
  cursor: not-allowed;
}

[aria-hidden=false][hidden] {
  display: initial;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a img {
  outline: 0;
}

h1, h2, h3, h4 {
  margin: 0;
  font-size: inherit;
  font-weight: 300;
}

nav li:before {
  content: none;
}

body {
  font-size: .8125rem;
  letter-spacing: 0;
  line-height: 1.8;
}

.c-fns, .c-stockist {
  background: #fff;
  margin: 0 -1.25rem;
}

.c-fns__inner, .c-stockist__inner {
  padding: 1.875rem 1.25rem 1.55rem;
}

.c-fns__area, .c-stockist__area {
  color: #fff;
  background: #a7a7a7;
  display: inline-flex;
  align-items: center;
  padding: .175rem .5rem;
}

.c-fns__name, .c-stockist__name {
  margin: .71875rem 0 1.39375rem;
}

.c-fns__ln, .c-stockist__ln {
  padding: 0 0 3.125rem;
}

.c-fns__ln__btn, .c-stockist__ln__btn {
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  color: #fff;
  background: #c1c1b8;
  width: 5.75rem;
  height: 2.5rem;
}

.c-fns__ln__btn:hover, .c-stockist__ln__btn:hover {
  opacity: .7;
}

.c-article__nav__btn svg, .c-article__sns a svg, .c-button svg,
.c-fns__ln__btn svg, .c-headline__close svg, .c-information__li svg,
.c-ln--line svg, .c-pagination__btn svg, .c-stockist__ln__btn svg,
.g-footer a svg, .p-about-fnm__register__btn svg {
  transform: translateZ(0);
}

.c-athlete__history dt, .c-ol--slim li:before, .c-ol__heading:before, .c-sans--a,
.c-sans--ab {
  font-family: Arial, sans-serif;
}

.l-content {
  padding: 2.375rem 1.25rem 7.5rem;
}

.l-headline {
  background: #000;
}

.l-members, .l-menu, .l-menu:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.l-members, .l-menu {
  position: fixed;
  z-index: 128;
  overflow: hidden;
  display: none;
}

.is-members .l-members, .is-menu .l-menu {
  display: block;
}

.l-menu:after {
  content: '';
  background: rgba(255, 255, 255, .95);
  position: absolute;
  z-index: 0;
  opacity: 0;
}

.is-menu-open .l-menu:after {
  animation: fade-in .73s forwards;
}

.is-menu-close .l-menu:after {
  animation: fade-in .73s backwards;
}

.l-wrapper__inner {
  background: #f5f5f2;
}

.c-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 5rem;
  font-size: .8125rem;
  line-height: 1.230769230769231;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.c-article__sns a:hover, .c-button:hover, .c-ln--line:hover {
  opacity: .7;
}

.c-button--map {
  background: #fff url(/assets/home/map.svg) no-repeat top 50% right 1.5625rem;
  background-size: .9375rem;
}

.c-button--list {
  background: #fff url(/assets/home/list.svg) no-repeat top 50% right 1.5625rem;
  background-size: .875rem;
}

.c-button--left {
  background: #fff url(/assets/ui/arrow-left.svg) no-repeat top 50% left 1.25rem;
  background-size: .9375rem;
}

.c-button--right {
  background: #fff url(/assets/ui/arrow-right.svg) no-repeat top 50% right 1.25rem;
  background-size: .9375rem;
}

.c-button--gray {
  background-color: #a7a7a7;
  background-image: url(/assets/ui/arrow-left.w.svg);
  color: #fff;
}

.c-button--gray--ln {
  background-color: #c1c1b8;
  background-image: url(/assets/ui/arrow-right.w.svg);
  color: #fff;
}

button.c-button, input.c-button {
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: 0;
}

.c-button--mini-r {
  height: 4.75rem;
  border-radius: .25rem;
  background: #c1c1b8;
  color: #fff;
}

.c-container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.c-serif, .c-serif--bold {
  font-family: 'EB Garamond', serif;
}

.c-sans, .c-sans--b {
  font-family: 'Helvetica Neue', Helvetica, Arial sans-serif;
}

.c-serif {
  font-weight: 400;
}

.c-serif--bold {
  font-weight: 700;
}

.c-sans {
  font-weight: 400;
}

.c-sans--b {
  font-weight: 700;
}

.c-sans--a {
  font-weight: 400;
}

.c-sans--ab {
  font-weight: 700;
}

.c-ja {
  font-family: 'Hiragino Sans',
                 'ヒラギノ角ゴ ProN W3',
                 "Hiragino Kaku Gothic ProN",
                 "メイリオ",
                 Meiryo,
                 "ＭＳ Ｐゴシック",
                 "MS PGothic",
                 sans-serif;
  font-weight: 300;
}

.c-footer {
  margin: 4.675rem 0 0;
  padding: 0 1.25rem;
}

.c-footer__label {
  text-align: center;
  margin: 0 0 .425rem;
}

.c-footer__button--left {
  justify-content: flex-start;
  padding: 0 0 0 1.625rem;
  font-size: 1.125rem;
}

.c-footer__button--left small {
  font-size: .6875rem;
  display: block;
  margin: .3125rem 0 0;
}

.c-fmt--a, .c-fmt--a__ly {
  position: relative;
}

.c-fmt--a__ly {
  z-index: 0;
  width: 94.02985074626866%;
}

.c-fmt--a__tx {
  z-index: 1;
  background: #fff;
  padding: 1.875rem 1.25rem;
  margin: -14.925373134328357% 0 0 auto;
}

.c-fmt--a__heading {
  margin: 0 0 2.45625rem;
}

.c-heading--1 {
  font-size: 1.625rem;
  line-height: 1.5;
  margin: 0 0 5.3rem;
  text-align: center;
}

header.c-heading--1 p, header.c-page__heading p {
  font-size: .6875rem;
  line-height: 1.181818181818182;
}

.c-heading--2, .c-heading--2n {
  display: block;
  font-size: 1.125rem;
  line-height: 1.5;
  margin: 4.58125rem 0 3.51875rem;
}

.c-heading--2:after {
  display: block;
  width: 1.875rem;
  height: .25rem;
  content: '';
  background: #c1c1b8;
  margin: .96875rem 0 0;
}

.c-heading--3 {
  display: block;
  font-size: 1rem;
  line-height: 1.4625;
  font-weight: 300;
  margin: 3.51875rem 0 1.64375rem;
}

.c-heading--4 {
  font-weight: 600;
  margin: 1.55rem 0 .925rem;
}

.c-h2 {
  font-size: 1.375rem;
  line-height: 1.5;
}

.c-h3 {
  font-size: 1.125rem;
  line-height: 1.3;
}

.c-ihd {
  margin: 0 -1.25rem 3.75rem;
}

.c-ihd__inner {
  max-width: 840px;
  margin: 0 auto;
  padding: 1.25rem;
}

.c-lead {
  margin: 0 auto 3.425rem;
}

.c-lead__h {
  margin: 0 0 2.45625rem;
}

.c-lead--narrow {
  max-width: 550px;
}

.c-ln--blank {
  display: inline-flex;
  align-items: center;
}

.c-ln--blank:after {
  content: '';
  width: .615384615384615em;
  height: .615384615384615em;
  margin: 0 0 0 .692307692307692em;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg id='レイヤー_1' data-name='レイヤー 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath d='M0,0V8H8V6H7V7H1V1H2V0ZM4,0,5.5,1.5,3,4,4,5,6.5,2.5,8,4V0Z'/%3E%3C/svg%3E");
}

.c-ln--line {
  display: inline-block;
  opacity: 0;
  font-size: .8125rem;
  line-height: 1.230769230769231;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  min-width: 6.4375rem;
}

.c-ln--line hr {
  width: 0;
  margin: 0 auto 0 0;
  border-color: #000;
}

.c-ln--line__inner {
  text-align: center;
  padding: 0 .375rem .5rem;
}

.c-ln--line.is-show {
  animation: iv-2 .7s forwards ease-in-out;
}

.c-ln--line.is-show hr {
  animation: iv-ln-hr .7s forwards .7s ease-in-out;
}

.c-b2t img:hover, .c-ln--line.is-appeared {
  opacity: 1;
}

.c-ln--line.is-appeared hr {
  width: 100%;
}

.c-ln--line.is-appeared:hover hr {
  animation: iv-ln-hr .7s forwards ease-in-out;
}

.c-ln--line.c-sans {
  line-height: 1.307692307692308;
}

.c-ln--wht {
  color: #fff;
}

.c-ln--wht hr {
  border-color: #fff;
}

.c-ln--more {
  min-width: 6.4375rem;
}

.c-ln--map__inner, .c-ln--more__inner {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .375rem .5rem;
  width: 100%;
}

.c-ln--more__inner:after {
  display: block;
  content: '';
  width: .307692307692308em;
  height: .307692307692308em;
  background: #000;
  border-radius: 50%;
}

.c-ln--map__inner:after {
  content: '';
  width: .615384615384615em;
  height: .615384615384615em;
  margin: 0 0 0 .692307692307692em;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg id='レイヤー_1' data-name='レイヤー 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath d='M0,0V8H8V6H7V7H1V1H2V0ZM4,0,5.5,1.5,3,4,4,5,6.5,2.5,8,4V0Z'/%3E%3C/svg%3E");
}

.c-ln--wide {
  width: 100%;
}

.c-ln--gry {
  color: #c1c1b8;
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
}

.c-ln--gry:hover {
  text-decoration: none;
}

.c-ln--gry:before {
  content: '';
  width: .3125rem;
  height: .5rem;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 5 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m.877 7.954-.707-.707 3.246-3.247-3.246-3.247.707-.707 3.953 3.954z' fill='%23c1c1b8'/%3E%3C/svg%3E") no-repeat 50% 50%;
  background-size: contain;
  margin: 0 .5rem 0 0;
}

.c-ln--fig__label {
  margin: 1.25rem 0 0;
  background: url(/assets/ui/arrow-right.svg) no-repeat 100% 50%;
  background-size: 1rem;
}

.c-ln--fig__label small {
  margin: 0 0 0 1em;
}

.c-ln--fig__tx {
  margin: .925rem 0 0;
}

.c-ln--fig.is-current {
  pointer-events: none;
}

.c-ln--fig.is-current figure,
.p-shop__detail__swiper__thumbnail.is-current picture {
  opacity: .4;
}

.c-ln--u {
  text-decoration: underline;
}

.c-breadcrumbs a:hover, .c-ln--u:hover {
  text-decoration: none;
}

.c-ol {
  counter-reset: order;
  list-style: none;
}

.c-ol__heading {
  display: flex;
  align-items: center;
  counter-increment: order;
  margin: 0 0 1.55rem;
}

.c-ol__heading:before {
  content: counter(order);
  background: #c1c1b8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  margin: 0 .75rem 0 0;
}

.c-ol__heading__inner {
  flex: 1 1;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
}

.c-ol li+li {
  margin: 3.125rem 0 0;
}

.c-ol--slim li {
  counter-increment: order;
  display: flex;
}

.c-ol--slim li+li {
  margin: 1.25rem 0 0;
}

.c-ol--slim li:before {
  content: counter(order);
  background: #c1c1b8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  margin: 0 .75rem 0 0;
}

.c-ol--slim p {
  flex: 1 1;
}

.c-page__heading {
  font-size: 1.625rem;
  line-height: 1.5;
  margin: 0 0 5.625rem;
  text-align: center;
}

.c-page__inner>:first-child {
  margin-top: 0;
}

.c-page__inner>:last-child {
  margin-bottom: 0;
}

.c-page__inner>p {
  margin-top: -.325rem;
}

.c-page__inner p+p {
  margin-top: 1.8em;
}

.c-page__h2 {
  font-size: 1.125rem;
  line-height: 1.6;
  margin: 3.0875rem 0 2.5rem;
  padding: 0 0 .9125rem;
  border-bottom: 1px solid #dddddb;
}

.c-page__h3 {
  margin-top: 1.8em;
  font-size: inherit;
  font-weight: 600;
}

.c-page__h3+p {
  margin-top: 0;
}

.c-page__ul {
  margin: .925rem 0;
}

.c-page__ul li {
  padding: 0 0 0 1em;
  text-indent: -1em;
}

.c-page__ul li:before {
  content: '●';
  color: #c1c1b8;
}

.c-page__ul li+li {
  margin-top: -.025rem;
}

.c-page__ul--dot li:before {
  content: '・';
  color: inherit;
  width: 1em;
  font-feature-settings: normal;
}

.c-rows {
  display: flex;
  flex-flow: column;
}

.c-row>:first-child {
  margin-top: 0;
}

.c-row--l {
  order: 1;
}

.c-row--r {
  order: 2;
  margin: 1.875rem 0 0;
}

.c-table__td, .c-table__th {
  border: solid #dddddb;
  border-width: 1px 0 0;
  padding: .925rem .625rem;
}

.c-table__th {
  border-color: #c1c1b8;
}

.c-table--s {
  display: flex;
  flex-flow: wrap;
}

.c-table--s .c-table__td, .c-table--s .c-table__th {
  padding: .6125rem .625rem;
}

.c-table--s .c-table__th, .c-tabs li, .p-faq__tabs li {
  width: 50%;
}

.c-table--s .c-table__td {
  width: 50%;
  margin: 0;
}

.c-table--bg {
  border: solid #dddddb;
  border-width: 1px 0 0 1px;
}

.c-table--bg .c-table__th {
  background: #ebebeb;
  border-color: #dddddb;
  border-width: 0 1px 1px 0;
}

.c-table--bg .c-table__td {
  background: #fff;
  border-width: 0 1px 1px 0;
}

.c-ts--3, .c-ts--6 {
  font-size: .6875rem;
  line-height: 1.5;
}

.c-ts--3 {
  font-size: 1.125rem;
}

.c-ts--4, .c-ts--5 {
  font-size: 1rem;
  line-height: 1.5;
}

.c-ts--5 {
  font-size: .875rem;
}

.c-ts--b {
  font-weight: 600;
}

.c-ts--gry {
  color: #a7a7a7;
}

.c-toggle+.c-toggle {
  margin-top: .0625rem;
}

.c-toggle__hd {
  background: #fff url(/assets/ui/toggle-open.svg) no-repeat top 50% right 1.875rem;
  background-size: .625rem;
  padding: .6125rem 3.125rem .6125rem 1.25rem;
  cursor: pointer;
  transition-duration: .4s;
  transition-property: color background-color;
  font-size: inherit;
  font-weight: 300;
}

.c-toggle__inner {
  padding: .6125rem .625rem 1.55rem;
  overflow: hidden;
}

.c-toggle.is-open .c-toggle__hd {
  color: #fff;
  background-image: url(/assets/ui/toggle-close.svg);
  background-color: #c1c1b8;
}

.c-toggle.is-open .c-toggle__inner {
  display: block;
}

.c-toggle--arrow .c-toggle__hd {
  background-image: url(/assets/ui/arrow-small.blk.svg);
  background-color: transparent;
  padding: 1.25rem 2.5rem 1.25rem 1.25rem;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5;
  font-weight: 600;
}

.c-toggle--arrow.is-open .c-toggle__hd {
  color: inherit;
  background-color: transparent;
  background-image: url(/assets/ui/arrow-small.blk.up.svg);
}

.c-article__meta {
  line-height: 1.153846153846154;
  padding: 0 0 1rem;
  margin: 0 0 1.9375rem;
  border-bottom: 1px solid #dddddb;
}

.c-article__meta__tag {
  color: #a7a7a7;
  margin: 0 0 0 .75rem;
}

.c-article__heading {
  font-size: 1.375rem;
  line-height: 1.681818181818182;
  margin: 0 0 3.28125rem;
}

.c-article__eyecatch {
  margin: 0 0 3.425rem;
}

.c-article__eyecatch .c-iv-i:before, .c-athlete__li .c-iv-i:before {
  padding: 0 0 75%;
}

.c-article__content>:first-child {
  margin-top: 0;
}

.c-article__content h2 {
  font-size: 1.125rem;
  line-height: 1.3;
  margin: 4.007692307692308em 0 1.7em;
  font-weight: 600;
}

.c-article__content p {
  margin-top: 1.8em;
}

.c-article__content img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  margin-top: 1.4em;
}

.c-article__content>p+img {
  margin-top: 4.215384615384616em;
}

.c-article__footer {
  margin: 3.75rem 0 0;
}

.c-article__sns, .c-article__sns i {
  display: flex;
  justify-content: center;
}

.c-article__sns {
  margin: 0 0 3.75rem;
}

.c-article__sns a {
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.c-article__sns a+a {
  margin: 0 0 0 .375rem;
}

.c-article__sns i {
  align-items: center;
  border: 1px solid #dddddb;
  background: #fff;
  border-radius: 50%;
  width: 1.9375rem;
  height: 1.9375rem;
}

.c-article__sns i img, .c-iv-i__bg picture {
  display: block;
}

.c-article__sns--fb img {
  width: .9375rem;
  height: .9375rem;
}

.c-article__sns--tw img {
  width: 1.0625rem;
  height: .875rem;
}

.c-article__sns--line img {
  width: .9375rem;
  height: .9375rem;
}

.c-article__nav {
  border: solid #dddddb;
  border-width: 1px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .9375rem 0;
  font-size: .6875rem;
}

.c-article__nav__btn {
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  align-items: center;
}

.c-article__nav__btn:hover {
  opacity: .7;
}

.c-article__nav__btn--next:after, .c-article__nav__btn--prev:before {
  width: .923076923076923em;
  height: .923076923076923em;
  display: block;
  content: '';
  background-size: contain;
}

.c-article__nav__btn--next:after {
  background: url(/assets/ui/arrow-right-mini.svg) no-repeat 50% 50%;
  margin: 0 0 0 1.076923076923077em;
}

.c-article__nav__btn--prev:before {
  background: url(/assets/ui/arrow-left-mini.svg) no-repeat 50% 50%;
  margin: 0 1.076923076923077em 0 0;
}

.c-article__nav__btn--index {
  flex-flow: column;
  margin: 0 0 -.325rem;
}

.c-article__nav__btn--index:before {
  width: 1.384615384615385em;
  height: 1.384615384615385em;
  display: block;
  content: '';
  background: url(/assets/ui/icons/all.svg) no-repeat 50% 50%;
  background-size: contain;
  margin: 0 0 .523076923076923em;
}

.c-athletes {
  display: flex;
  flex-flow: wrap;
}

.c-athlete__li figcaption, .p-press-archive__li figcaption {
  margin: 1.230769230769231em 0 0;
}

.c-athlete__li figcaption small {
  font-size: .6875rem;
  display: block;
  margin: 0 0 .128125rem;
}

.c-athlete__heading {
  margin: 2.175rem 0 3.234375rem;
  text-align: center;
}

.c-athlete__heading__en {
  font-size: 1.875rem;
}

.c-athlete__sct__heading {
  margin: 0 0 2.45625rem;
}

.c-athlete__sct__inner {
  margin: 0 0 1.225rem;
}

.c-athlete__sct__ln {
  display: flex;
  flex-flow: wrap;
}

.c-athlete__sct__ln a {
  margin: 0 1.875rem 0 0;
}

.c-athlete__list {
  border: solid #dddddb;
  border-width: 1px 0;
  padding: 1.55rem .625rem;
}

.c-athlete__profile {
  border-width: 1px 0 0;
  margin: 2.8rem 0 0;
}

.c-athlete__profile h4 {
  margin: 0 0 .85rem;
}

.c-athlete__history>p {
  margin: 1.55rem 0 0;
}

.c-athlete__history dt {
  font-weight: 600;
  color: #a7a7a7;
}

.c-b2t, .c-b2t img {
  transition: opacity .3s;
}

.c-b2t {
  position: fixed;
  right: .75rem;
  bottom: .75rem;
  z-index: 64;
  opacity: 0;
  pointer-events: none;
}

.c-b2t img {
  display: block;
  opacity: .5;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

.is-scrolled .c-b2t {
  opacity: 1;
}

.is-scrolled .c-b2t img {
  pointer-events: auto;
}

.c-breadcrumbs {
  border-top: 1px solid #dddddb;
}

.c-breadcrumbs__inner {
  padding: 1.25rem;
  font-size: .75rem;
  line-height: 1.666666666666667;
  display: flex;
  flex-flow: wrap;
}

.c-breadcrumbs__li {
  display: inline-flex;
  margin: 0 .625rem 0 0;
}

.c-breadcrumbs__li+.c-breadcrumbs__li:before {
  content: '';
  width: .25rem;
  height: .5rem;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 5 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m.877 7.954-.707-.707 3.246-3.247-3.246-3.247.707-.707 3.953 3.954z'/%3E%3C/svg%3E") no-repeat 50% 50%;
  background-size: contain;
  margin: .4375rem .625rem 0 0;
}

.c-breadcrumbs a {
  text-decoration: underline;
}

.c-caption {
  font-size: .8125rem;
  line-height: 1.6;
}

.c-collections__inner {
  margin: -2.0625rem 0 0;
  display: flex;
  flex-flow: wrap;
}

.c-collections__li {
  display: block;
  font-size: .6875rem;
  line-height: 1.090909090909091;
}

.c-collections__li p, .c-journal__li p {
  margin: .75rem 0 0;
  text-align: center;
}

.c-collections__li .c-iv-i:before {
  padding: 0 0 80.15873015873017%;
}

.c-collections__li .c-iv-i+.c-iv-i__t.is-show,
.c-journal__li .c-iv-i+.c-iv-i__t.is-show,
.p-home__collections__li .c-iv-i+.c-iv-i__t.is-show {
  animation: iv-i-2 .7s forwards ease-in-out;
}

.c-collections__view-all {
  margin: 3.125rem 0 0;
  text-align: center;
}

.c-content__inner {
  padding: 3.125rem 1.25rem;
}

.c-content__footer {
  margin: 5rem 0 0;
}

.c-fade-in {
  opacity: 0;
}

.c-fade-in.is-show {
  animation: fade-in 1s forwards;
}

.c-flc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-form__table {
  margin: 2.5rem 0 0;
}

.c-form__tr {
  border: solid #dddddb;
  border-width: 1px 0 0;
  padding: .925rem .3125rem;
}

.c-form__tr:last-child {
  border-width: 1px 0;
}

.c-form__th {
  display: flex;
  justify-content: space-between;
}

.c-form__td {
  margin-top: .6rem;
}

.c-form__req {
  color: #dc000c;
  border: 1px solid red;
  background: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: .6875rem;
  letter-spacing: 0;
  line-height: 1.909090909090909;
  font-weight: 600;
  height: 1.4375rem;
  padding: 0 .3125rem;
}

.c-form__zip-ln {
  text-decoration: underline;
  margin: 0 0 0 1.25rem;
  color: #c1c1b8;
}

.c-form__zip-ln:hover {
  text-decoration: none;
}

.c-form__terms {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin: 2.5rem 0 0;
  height: 12.5rem;
  border: 1px solid #dddddb;
  background: #fff;
  border-radius: .1875rem;
}

.c-form__terms__inner {
  padding: 1.05rem 1.25rem;
}

.c-form__agreement {
  text-align: center;
  margin: 2.5rem 0 0;
}

.c-form__footer {
  margin: 3.75rem 0 0;
}

.c-form__footer--confirm {
  display: flex;
  flex-flow: column;
}

.c-form__footer--confirm .c-button {
  margin: 0;
}

.c-headline {
  position: relative;
  overflow: hidden;
}

.c-headline__inner {
  padding: .675rem 2.8125rem .675rem .625rem;
  display: flex;
  justify-content: center;
  color: #fff;
}

.c-headline__close, .c-information__li {
  display: block;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.c-headline__close {
  position: absolute;
  width: .9375rem;
  height: .9375rem;
  right: .9375rem;
  top: .9375rem;
  cursor: pointer;
  background: url(/assets/ui/close.svg) no-repeat 50% 50%;
  background: contain;
}

.c-headline__close:hover, .c-information__li:hover, a:hover .c-iv-i {
  opacity: .7;
}

.c-information__li {
  font-size: .8125rem;
  line-height: 1.8;
}

.c-information__li time {
  line-height: .088757396449704;
}

.c-information__li hr {
  width: 0;
}

.c-information__li__t {
  overflow: hidden;
  position: relative;
  padding: 1.875rem 0 2.1875rem;
  opacity: 0;
}

.c-information__li.is-show hr {
  animation: iv-info-hr .7s forwards ease-in-out;
}

.c-information__li.is-show .c-information__li__t {
  animation: iv .7s forwards .7s ease-in-out;
}

.c-input {
  -webkit-appearance: none;
          appearance: none;
  border-radius: .1875rem;
  border: 1px solid #dddddb;
  background: #fff;
  padding: .6125rem .5rem;
  width: 100%;
  font-family: inherit;
}

.c-input:-ms-input-placeholder, .c-input:-ms-input-placeholder-shown {
  color: #a7a7a7;
}

.c-input::placeholder, .c-input::placeholder-shown {
  color: #a7a7a7;
}

textarea.c-input {
  height: 10.499999999999998rem;
}

select.c-input {
  max-width: 100%;
  width: auto;
  padding-right: 2.375rem;
  background: #fff url(/assets/ui/arrow-small.svg) no-repeat top 50% right .9375rem;
  background-size: .5rem;
}

select.c-input:not(.is-selected) {
  color: #a7a7a7;
}

.c-input--zip {
  width: 6.25rem;
}

.c-input--checkbox {
  cursor: pointer;
  display: inline-flex;
}

.c-input--checkbox__el {
  display: none;
}

.c-input--checkbox__label {
  margin: 0 0 0 .625rem;
  line-height: 2.307692307692307;
}

.c-input--checkbox__icon {
  display: block;
  position: relative;
  width: 1.875rem;
  height: 1.875rem;
  border: 1px solid #dddddb;
  border-radius: .1875rem;
}

.c-input--checkbox__el:checked+.c-input--checkbox__icon {
  background: #000;
}

.c-input--checkbox__el:checked+.c-input--checkbox__icon:after {
  content: '';
  display: block;
  position: absolute;
  top: .6228125rem;
  left: .509375rem;
  width: .73125rem;
  height: .504375rem;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 11.696 8.066' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4.638 8.066-4.638-4.638.941-.941 3.7 3.63 6.114-6.117.945.941z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%;
  background-size: contain;
}

.c-iv {
  opacity: 0;
}

.c-iv.is-show {
  animation: iv 1.2s forwards ease-in-out;
}

.c-iv-i {
  background: #f5f5f2;
  overflow: hidden;
  position: relative;
}

.c-iv-i:before, .c-logo:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
}

.c-iv-i:before {
  padding: 0 0 100%;
  z-index: -1;
}

.c-iv-i, .c-iv-i a {
  transition: opacity .74s;
}

.c-iv-i__bg, .c-iv-i__bg img, .c-iv-i__ly {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.c-iv-i__veil, select.c-select option[disabled] {
  display: none;
}

.c-iv-i__ly {
  z-index: 1;
}

.c-iv-i__t {
  opacity: 0;
}

.c-iv-i__t.is-show {
  animation: iv .7s forwards ease-in-out;
}

.c-iv-i__bg {
  z-index: 0;
}

.c-iv-i__bg img {
  opacity: 0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.c-iv-i__bg img, .c-iv-i__bg img a {
  transition: transform .74s;
}

.p-vision-lab__li__grid:hover img, a:hover .c-iv-i__bg img {
  transform: scale(1.05);
}

.c-iv-i.is-show .c-iv-i__bg, .c-iv-i.is-show .c-iv-i__t {
  opacity: 1;
}

.c-journal__wrapper {
  margin: 0 0 2.5rem;
  display: flex;
  flex-flow: wrap;
}

.c-journal__li {
  display: block;
  font-size: .6875rem;
  line-height: 1.090909090909091;
  width: 50%;
}

.c-journal__li .c-iv-i:before {
  padding: 0 0 72.1311475409836%;
}

.p-company__map iframe.lazyload, .p-company__map iframe.lazyloading,
img.lazyload, img.lazyloading {
  opacity: 0;
}

img.lazyloaded {
  animation: fade-in .5s forwards ease-in;
}

.c-lens-c+.c-lens-c {
  margin-top: 5rem;
}

.c-lens-c__header {
  display: flex;
  margin: 0 0 2.5rem;
}

.c-lens-c__header__num {
  display: flex;
  border-right: 1px solid #dddddb;
  color: #a7a7a7;
  width: 2.5rem;
  margin: 0 1.25rem 0 0;
}

.c-lens-c__header__column__t, .c-lens-c__header__inner {
  flex: 1 1;
}

.c-lens-c__header__column__i {
  margin: 1.25rem 0 0;
}

.c-lens-c__heading {
  margin: 0 0 1.55rem;
}

.c-lens-c__heading--3 {
  color: #a7a7a7;
  margin: 0 0 1.3rem;
}

.c-lens-c__heading--4 {
  font-weight: 600;
  margin: .925rem 0;
}

.c-lens-c__inner {
  margin: 0 -1.25rem;
}

.c-lens-c__fig {
  background: #fff;
  padding: 2.5rem 1.25rem;
}

.c-lens-c__toggle {
  margin-top: 1px;
}

.c-lens-c__toggle .c-toggle__inner {
  background: #fff;
  padding: 1.25rem;
}

.c-lens-c__column__l {
  flex: 1 1;
}

.c-lens-c__column__r {
  padding: 3.125rem 2.5rem 0;
}

.c-lens-c__note {
  margin-top: 1.225rem;
}

.c-logo, .c-map {
  position: relative;
}

.c-logo:before {
  padding: 0 0 55.96433304134941%;
}

.c-logo img, .c-pic img {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.c-logo img, .c-map__inner, .c-pic img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-fit {
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.c-fit--contain {
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

.c-pagination {
  padding: 3.59375rem 0 0;
  text-align: center;
}

.c-pagination__meta {
  font-size: .625rem;
  line-height: 1.5;
  margin: 0 0 .46875rem;
}

.c-pagination__inner {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}

.c-pagination__el {
  font-size: .75rem;
  line-height: 1.5;
  width: 1.625rem;
  height: 1.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  margin: .125rem;
}

a.c-pagination__el:not(.c-pagination__btn) {
  background: #fff;
  border-radius: .1875rem;
  border: 1px solid #dddddb;
}

a.c-pagination__el:not(.c-pagination__btn):not(.is-current) {
  transition-property: color background-color border-color;
  transition-duration: .2s;
  cursor: pointer;
}

a.c-pagination__el:not(.c-pagination__btn):not(.is-current):hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

a.c-pagination__el:not(.c-pagination__btn).is-current {
  color: #fff;
  background: #000;
  border-color: #000;
  pointer-events: none;
}

.c-pagination__sep {
  margin: 0;
  padding-bottom: .125rem;
}

.c-pagination__sep:after {
  content: '…';
}

.c-pagination__btn {
  background: no-repeat 50% 50%;
  background-size: .75rem;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.c-pagination__btn:hover {
  opacity: .7;
}

.c-pagination__prev {
  background-image: url(/assets/ui/arrow-left-mini.svg);
  margin-right: .6875rem;
}

.c-pagination__next {
  background-image: url(/assets/ui/arrow-right-mini.svg);
  margin-left: .6875rem;
}

.c-pic {
  display: block;
  position: relative;
  overflow: hidden;
}

.c-pic:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  padding: 0 0 100%;
}

.c-pic--cover img {
  object-fit: cover;
}

select.c-select {
  -webkit-appearance: none;
          appearance: none;
  border: 1px solid #dddddb;
  border-radius: 0;
  outline: 0;
  color: #a7a7a7;
  padding: .6em 2.923076923076923em .6em 1.230769230769231em;
  background: #fff url(/assets/ui/arrow-small.svg) no-repeat top 50% right 1.153846153846154em;
  background-size: .692307692307692em;
}

select.c-select.is-selected {
  color: inherit;
}

.c-fns+.c-fns {
  margin-top: .75rem;
}

.c-fns__items {
  margin-top: 1.507692307692308em;
}

.c-fns__items b {
  display: block;
  font-weight: 600;
  margin: 0 0 .430769230769231em;
}

.c-shop-li {
  font-size: .6875rem;
  letter-spacing: 0;
  line-height: 1.727272727272727;
  color: #83827c;
  padding: 2.25rem 1.875rem;
}

.c-shop-li__wrapper {
  padding: .625rem 0 0;
}

.c-shop-li+.c-shop-li {
  border-top: 1px solid #dddddb;
}

.c-shop-li__name {
  color: #000;
}

.c-shop-li__area, .c-shop-li__desc, .c-shop-li__name {
  margin: 0 0 .75rem;
}

.c-shop-li__items p+p {
  margin-top: .636363636363636em;
}

.c-shop-li__ln {
  margin: 1.90625rem 0 0;
  padding: 0 .1875rem;
}

.c-shop-li__btn {
  display: block;
  color: #000;
}

.c-shop-li__btn+.c-shop-li__btn {
  margin: 1.875rem 0 0;
}

.c-stockist+.c-stockist {
  margin-top: .125rem;
}

.c-sz {
  display: block;
  width: 100%;
  height: 0;
  padding: 0 0 56.25%;
}

.c-spacer {
  width: 100%;
  height: 1.25rem;
  pointer-events: none;
  visibility: hidden;
}

.c-tabs {
  display: flex;
  flex-flow: wrap;
}

.c-tabs a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #dddddb;
  padding: .8125rem 0;
  transition-property: color border-color;
  transition-duration: .3s;
}

.c-tabs .is-current a, .c-tabs a:hover {
  border-color: #c1c1b8;
  color: #c1c1b8;
}

.c-tabs .is-current a {
  pointer-events: none;
}

.g-header__menu__btn svg, .u-abs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.g-header__ln span, .u-hide--m {
  display: none;
}

.p-about-fnm__logo {
  position: relative;
  width: 15.5625rem;
  margin: 0 auto .4875rem;
}

.p-about-fnm__li__icon:before, .p-about-fnm__logo:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  padding: 0 0 7.352941176470589%;
}

.g-footer__sns a img, .p-about-fnm__li__icon img, .p-about-fnm__logo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.p-about-fnm .c-heading--1 {
  margin: 0 0 2.8rem;
}

.p-about-fnm__lead {
  margin: 0 0 1.2375rem;
}

.p-about-fnm__what {
  margin: 0 -1.25rem;
}

.p-about-fnm__what__header {
  background: #fff;
  padding: 2.3125rem 0 4.8125rem;
  margin: 0 0 -2.5rem;
  font-size: 1.125rem;
  line-height: 1.333333333333333;
  text-align: center;
}

.p-about-fnm__what__inner {
  padding: 0 1.25rem 2.625rem;
  max-width: 1320px;
  margin: 0 auto;
}

.p-about-fnm__register {
  background: #fff;
  padding: 2.175rem 1.25rem 2.5rem;
  margin: 0 -1.25rem;
}

.p-about-fnm__register__heading {
  font-size: 1.125rem;
  line-height: 1.333333333333333;
  text-align: center;
  margin: 0 0 2.3125rem;
}

.p-about-fnm__register__inner {
  font-size: .6875rem;
  line-height: 1.545454545454545;
}

.p-about-fnm__register__item {
  font-size: .8125rem;
}

.p-about-fnm__register b {
  display: block;
  text-align: center;
  font-weight: 600;
  margin: 0 0 .4375rem;
}

.p-about-fnm__register__btn {
  display: flex;
  border-radius: .25rem;
  width: 100%;
  height: 4.75rem;
  background: #72accb;
  color: #fff;
  font-weight: 600;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.g-footer a:hover, .p-about-fnm__register__btn:hover {
  opacity: .7;
}

.p-about-fnm__register__btn--line {
  background: #01b901;
}

.p-about-fnm__register__note {
  margin-top: .4375rem;
}

.p-about-fnm__faq {
  margin: 0 -1.25rem;
}

.p-about-fnm__li {
  border: 1px solid #dddddb;
  border-radius: .5rem;
  background: #fff;
  padding: 2.175rem 1.875rem;
  height: 100%;
}

.p-about-fnm__li header {
  font-size: .875rem;
  margin: 0 -1.875rem .85rem;
  text-align: center;
  color: #a7a7a7;
  font-weight: 600;
}

.p-about-fnm__li__icon {
  display: block;
  position: relative;
  width: 1.875rem;
  margin: 0 auto .8625rem;
}

.p-about-fnm__li__icon:before {
  padding: 0 0 100%;
}

.p-about-fnm__li__icon--2 {
  width: 4.625rem;
  margin-top: .4375rem;
}

.p-about-fnm__li__icon--2:before {
  padding: 0 0 31.08108108108108%;
}

.p-about-fnm__benefit .p-about-fnm__li header {
  text-align: left;
  margin: 0 0 .85rem;
}

.p-about-fnm__connect__item .c-heading--3 {
  font-weight: 600;
  margin-top: 0;
}

.p-about-fnm__connect__l {
  margin: 0 0 1.55rem;
}

.p-about-fnm__connect .c-pic {
  width: 15.875rem;
  margin: 0 auto;
}

.p-about-fnm__connect .c-pic:before {
  padding: 0 0 98.44961240310077%;
}

.p-about__ingot__tx {
  margin-top: -29.850746268656714%;
}

.p-about__banner {
  margin: 3.75rem 0 0;
  background: url(/assets/about/bg.jpg) no-repeat 0 0;
  background-size: cover;
}

.p-about__banner__inner {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 1.25rem;
}

.p-about__banner__tx {
  width: 15.375rem;
}

.p-about__banner__tx:before {
  padding: 0 0 41.46341463414634%;
}

.p-about__banner__btn {
  width: 6.4375rem;
  margin: 1.425rem 0 0;
}

.p-about__lead__fig {
  margin: 3.125rem 0 0;
}

.p-about__lead__fig:before {
  padding: 0 0 21.098626716604244%;
}

.p-about__lead__footer {
  margin: 3.75rem 0 0;
}

.p-about__heading--2 {
  font-size: 1.375rem;
  line-height: 1.063636363636363;
  margin: 0 0 3.08125rem;
}

.p-about__heading--3 {
  margin: 0 0 .9875rem;
  font-weight: 300;
}

.p-about__lineup {
  margin: 6.25rem 0 0;
}

.p-about__lineup__heading {
  margin: 0 0 1.25rem;
}

.p-about__lineup small {
  font-size: .6875em;
  margin: 0 0 0 1em;
}

.p-about__ln--0 .c-iv-i:before {
  padding: 0 0 47.368421052631575%;
}

.p-about__ln--1 .c-iv-i:before {
  padding: 0 0 26.31578947368421%;
}

.p-about__inquiry {
  border: solid #dddddb;
  border-width: 1px 0;
  padding: 2.175rem 0;
  text-align: center;
}

.p-about__inquiry b {
  font-weight: 600;
  display: block;
  font-size: .875rem;
  line-height: 1.5;
  margin: 0 0 1.21875rem;
}

.p-about__inquiry small {
  color: #a7a7a7;
  display: block;
  margin: .984375rem 0 0;
}

.p-about__aside, .p-about__inquiry {
  margin: 6.25rem 0 0;
}

.p-lens__header {
  background: url(/assets/about/four-nines-lens/hd-bg.png) 50% 50%;
  background-size: cover;
}

.p-lens__header--single-sight {
  background-image: url(/assets/about/four-nines-lens/single-sight/hd-bg.png);
}

.p-lens__header--multi-sight {
  background-image: url(/assets/about/four-nines-lens/multi-sight/hd-bg.png);
}

.p-lens__header__inner {
  height: 12.5rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.p-lens__header__inner p {
  font-size: .6875rem;
  line-height: 1.818181818181818;
}

.p-lens__header__heading {
  width: 17.8125rem;
}

.p-lens__header__heading:before {
  padding: 0 0 10.872331301713169%;
}

.p-lens__footer {
  margin: 8.125rem 0 0;
}

.p-lens__content, .p-lens__price {
  margin: 6.25rem 0 0;
}

.p-lens__price__note {
  margin: .625rem 0 0;
}

.p-contact__tel p+p, .p-lens__price__note p+p {
  margin: 0;
}

.p-lens__concept__fmt {
  margin: 3.75rem 0 0;
}

.p-lens__concept__fmt .c-pic:before, .p-lens__multi-sight__fmt .c-pic:before {
  padding: 0 0 63.57142857142857%;
}

.p-lens__concept__fig--0, .p-lens__concept__fig--2 {
  margin: 0 auto;
}

.p-lens__concept__fig--0:before {
  padding: 0 0 23.77952755905512%;
}

.p-lens__concept__fig--1:before {
  padding: 0 0 76.36363636363637%;
}

.p-lens__concept__fig--2:before {
  padding: 0 0 47.68518518518518%;
}

.p-lens__concept__fig--3 {
  display: flex;
  align-items: center;
  margin: 4.411764705882353% 0 0;
}

.p-lens__concept__fig--3--0, .p-lens__concept__fig--3--1 {
  width: 32.35294117647059%;
}

.p-lens__concept__fig--3--0:before, .p-lens__concept__fig--3--1:before {
  padding: 0 0 106.81818181818181%;
}

.p-lens__concept__fig--3--p {
  width: 5.294117647058823%;
  margin: -1.544117647058824%;
  position: relative;
  z-index: 1;
}

.p-lens__concept__fig--3--p:before {
  padding: 0 0 100%;
}

.p-lens__concept__fig--3--e {
  width: 5.294117647058823%;
  margin: 0 4.117647058823529% 0 2.352941176470588%;
}

.p-lens__concept__fig--3--e:before {
  padding: 0 0 50%;
}

.p-lens__concept__fig--3--2 {
  width: 27.205882352941174%;
}

.p-lens__concept__fig--3--2:before {
  padding: 0 0 26.486486486486488%;
}

.p-lens__concept__fig--4 {
  display: flex;
  align-items: center;
}

.p-lens__concept__fig--4--0 {
  width: 47.647058823529406%;
}

.p-lens__concept__fig--4--0:before {
  padding: 0 0 36.11111111111111%;
}

.p-lens__concept__fig--4--1 {
  width: 47.647058823529406%;
  margin: 0 0 0 4.705882352941177%;
}

.p-lens__concept__fig--4--1:before {
  padding: 0 0 36.41975308641975%;
}

.p-lens__concept__fig--5 {
  margin: 0 -8.181818181818182% 0 8.181818181818182%;
}

.p-lens__concept__fig--5:before {
  padding: 0 0 94.0909090909091%;
}

.p-lens__concept__fig--6 {
  margin: 1.875rem 0 0;
  width: 89.04255319148936%;
}

.p-lens__concept__fig--6:before {
  padding: 0 0 8.124253285543608%;
}

.p-lens__single-sight__fig--0:before {
  padding: 0 0 40.47244094488189%;
}

.p-lens__single-sight__fig--1:before {
  padding: 0 0 36.36363636363637%;
}

.p-lens__single-sight__fig--2:before {
  padding: 0 0 71.03448275862068%;
}

.p-lens__single-sight__fig--3 {
  margin: 5.833333333333333% 0 0;
}

.p-lens__single-sight__fig--3:before {
  padding: 0 0 21.52777777777778%;
}

.p-lens__single-sight__fig--4--0:before {
  padding: 0 0 40.416666666666664%;
}

.p-lens__single-sight__fig--4--1 {
  margin: 2.777777777777778% 0 0;
}

.p-lens__single-sight__fig--4--1:before {
  padding: 0 0 42.916666666666664%;
}

.p-lens__single-sight__fig--5:before {
  padding: 0 0 39.09090909090909%;
}

.p-lens__single-sight .c-column {
  margin-top: 1.875rem;
}

.p-lens__multi-sight__fmt {
  margin: 3.75rem 0 0;
}

.p-lens__multi-sight__fig--0:before {
  padding: 0 0 77.3529411764706%;
}

.p-lens__multi-sight__fig--1--0:before {
  padding: 0 0 40.416666666666664%;
}

.p-lens__multi-sight__fig--1--1 {
  margin: 2.777777777777778% 0 0;
}

.p-lens__multi-sight__fig--1--1:before {
  padding: 0 0 55.00000000000001%;
}

.p-lens__multi-sight__fig--2:before {
  padding: 0 0 39.09090909090909%;
}

.p-collection__li .c-iv-i:before {
  padding: 0 0 62.5%;
}

.p-collection__li+.p-collection__li {
  margin-top: 5.3rem;
}

.p-collection__li__label {
  display: flex;
  align-items: baseline;
  font-size: 1.375rem;
  line-height: 1.063636363636363;
  margin: 1.875rem 0 .6125rem;
  background: url(/assets/ui/arrow-right.svg) no-repeat 100% 55%;
  background-size: .9375rem;
}

.p-collection__li__label small {
  font-size: .5em;
  margin: 0 0 0 1.090909090909091em;
}

.p-collection__header {
  position: relative;
}

.p-collection__header .c-iv-i:before {
  padding: 0 0 35.714285714285715%;
}

.p-collection__header .c-iv-i {
  min-height: 300px;
}

.p-collection__header__inner {
  position: absolute;
  top: 66.15384615384615%;
  height: 33.84615384615385%;
}

.p-collection__logo {
  height: 31.363636363636367%;
}

.p-collection__logo img {
  display: block;
  width: auto;
  height: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.p-collection__inner {
  padding: 2.375rem 1.25rem 5rem;
}

.p-collection__lead h2 {
  margin: 0 0 2.75625rem;
}

.p-collection__lead .c-rows {
  margin: 3.01875rem 0 0;
}

.p-collection__sct+.p-collection__sct {
  margin: 6.25rem 0 0;
}

.p-collection__sct__header {
  padding: 0 0 1.15625rem;
  margin: 0 0 3.125rem;
  text-align: center;
  border-bottom: 1px solid #dddddb;
}

.p-collection__sct__header p {
  font-size: 4.25rem;
  line-height: 1.294117647058824;
  margin: 0;
}

.p-collection__sct__header small {
  display: block;
  font-size: .8125rem;
  line-height: 1.307692307692308;
  margin: 0 0 4.25rem;
}

.p-collection__sct h3 {
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 600;
}

.p-collection__sct h3:first-child {
  margin-top: -.16875rem;
}

.p-collection__sct p {
  margin: 1.225rem 0 0;
}

.p-collection__sct img {
  margin: 4.35rem 0 0;
  max-width: 100%;
  width: auto;
  height: auto;
  display: block;
}

.p-collection__lookbook header, .p-collection__other header,
.p-collection__stockist header {
  text-align: center;
  margin: 0 0 3.75rem;
}

.p-collection__lookbook__inner {
  background: #fff;
}

.p-collection__lookbook__banner {
  max-width: 1820px;
  margin: 0 auto;
}

.p-collection__lookbook__banner .c-iv-i:before {
  padding: 0 0 31.263736263736263%;
}

.p-collection__other__inner {
  padding: 3.75rem 1.25rem 3.125rem;
}

.p-collection__stockist {
  background: #ebebeb;
}

.p-collection__stockist__inner {
  padding: 3.125rem 1.25rem;
}

.p-collection-archive__footer {
  padding: 3.125rem 0 0;
}

.p-company__office {
  background: #fff;
}

.p-company__office+.p-company__office {
  margin-top: 1.25rem;
}

.p-company__map__inner {
  position: relative;
}

.p-company__map__inner:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  padding: 0 0 67.6923076923077%;
}

.p-company__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: grayscale(1) sepia(.15);
}

.p-company__map iframe.lazyloaded {
  animation: fade-in .7s forwards ease-in;
}

.p-company__data {
  padding: 1.875rem 1.25rem;
}

.p-company__data :first-child {
  margin-top: .325rem;
}

.p-company__data h4 {
  font-weight: 600;
  font-size: inherit;
  display: block;
  margin: 0 0 .6rem;
}

.p-company__google-map {
  text-align: center;
  margin: 1.55rem 0 0;
}

.p-contact__ln--shop {
  width: 9.25rem;
  margin-top: 2.175rem;
}

.p-contact__tel {
  border: 1px solid #dddddb;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 10.625rem;
}

.p-contact__tel p:nth-child(2) {
  font-size: 1.875rem;
  line-height: 1.3;
  margin: .625rem 0;
}

.p-contact__tel p:last-child {
  font-size: .75rem;
}

.p-contact--thanks {
  text-align: center;
}

.p-contact--thanks p {
  margin: 0 0 3.425rem;
}

.p-faq__tabs a {
  display: flex;
  flex-flow: column;
}

.p-faq__tabs a:after {
  content: '';
  width: .6875rem;
  height: .3125rem;
  background: url(/assets/faq/arrow.svg) no-repeat 50% 50%;
  background-size: contain;
  margin: .675rem auto 0;
  transition-property: opacity;
  transition-duration: .3s;
}

.p-faq__tabs a:hover:after, .p-store-guide__tabs a:hover:after {
  opacity: .3;
}

.p-faq__sct+.p-faq__sct {
  margin: 6.9875rem 0 0;
}

.g-footer {
  padding: 3.75rem 0 2.9375rem;
  background: #fff;
}

.g-footer .c-logo {
  width: 6.25rem;
  margin: 0 auto;
}

.g-footer a {
  display: inline-flex;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.g-footer__contents, .g-footer__sns {
  display: flex;
  border-top: 1px solid #dddddb;
  width: 100%;
}

.g-footer__contents {
  flex-flow: wrap;
  padding: 1.5625rem 0;
}

.g-footer__contents li {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .625rem 0;
}

.g-footer__contents--1 {
  font-size: 1rem;
  line-height: 1.125;
  padding: 3.75rem 0 3.9375rem;
}

.g-footer__contents--1, .g-footer__contents--2 {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.g-footer__contents--1 li:nth-child(n+2) {
  margin: 2.0625rem 0 0;
}

.g-footer__contents--2 {
  font-size: .8125rem;
  line-height: 1.153846153846154;
  padding: 0 0 2.5625rem;
}

.g-footer__contents--2 li:nth-child(n+2) {
  margin: 1.375rem 0 0;
}

.g-footer__contents--3 {
  line-height: 1.769230769230769;
  justify-content: center;
}

.g-footer__contents--3 li {
  width: auto;
  padding: 0;
}

.g-footer__contents--3 li:nth-child(n+2) {
  margin: 0 0 0 3.125rem;
}

.g-footer__contents--4 {
  font-size: .75rem;
  line-height: 1.666666666666667;
  color: #a0a0a0;
}

.g-footer__sns {
  padding: 2.75rem 0;
  justify-content: center;
}

.g-footer__sns a {
  display: flex;
  position: relative;
  width: 2.5rem;
}

.g-footer__sns a:before, .g-header__ln__icon:before, .g-header__menu__btn:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  padding: 0 0 100%;
}

.g-footer__sns a+a {
  margin: 0 0 0 2.875rem;
}

.g-footer__c {
  font-size: .75rem;
  line-height: 1.166666666666667;
  color: #a0a0a0;
  text-align: center;
  margin: 1.875rem 0 0;
}

.g-header {
  /* height: 3.625rem; */
  pointer-events: none;
  width: 100%;
  z-index: 64;
  background: 0 0;
  transition-property: background-color color;
  transition-duration: .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.g-header__inner > a{
  max-width: 100px;
  margin: 0 auto;
  display: block;
}

.is-scrolled:not(.is-invert) .g-header {
  background-color: #fff;
}

.is-invert .g-header {
  background-color: transparent;
  color: #fff;
}

.g-header__inner {
  position: relative;
  padding: .75rem 1.25rem;
}

.g-header a, .g-header__menu {
  pointer-events: auto;
}

.g-header .c-logo {
  width: 3.75rem;
  margin: 0 auto;
}

.g-header__menu {
  position: absolute;
  top: 1.0625rem;
  left: 1.25rem;
  cursor: pointer;
}

.g-header__menu__btn {
  position: relative;
  width: 1.5rem;
}

.g-header__ln {
  display: inline-flex;
  align-items: center;
  margin: 0 0 0 1.25rem;
}

.g-header__ln__wrapper {
  position: absolute;
  right: 2.125rem;
  top: 1.25rem;
  display: flex;
  align-items: center;
}

.g-header__ln__icon {
  display: inline-block;
  background: no-repeat 0 0;
  background-size: contain;
  position: relative;
}

.g-header__ln__icon img, .g-members__close img, .g-members__header i img,
.g-members__logo__inner img, .g-menu__close i img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.g-header__ln__icon--search {
  width: 1.05rem;
}

.g-header__ln__icon--search:before {
  padding: 0 0 100%;
}

.g-header__ln__icon--shop {
  width: .875rem;
}

.g-header__ln__icon--shop:before {
  padding: 0 0 120%;
}

.g-header__ln__icon--members {
  width: .945rem;
}

.g-header__ln__icon--members:before, .g-members__header i:before {
  padding: 0 0 111.11111111111111%;
}

.g-header__i--b, .g-header__i--w {
  transition: opacity .3s;
}

.g-header__i--w {
  opacity: 0;
}

.g-header__i--b, .is-invert .g-header__i--w {
  opacity: 1;
}

.is-invert .g-header__i--b {
  opacity: 0;
}

.g-header__members {
  pointer-events: auto;
  cursor: pointer;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.g-header__members:hover {
  opacity: .7;
}

.g-header__members svg, .g-members__close svg, .g-members__li svg,
.g-menu__close__btn svg, .g-menu__li svg, .g-menu__sns a svg,
.p-shop__detail__banner svg, .p-shop__detail__swiper__thumbnail svg,
.p-shop__list__side__a svg, .p-shop__tab svg, .p-store-top__swiper a svg,
.p-vision-lab__footer__ln svg {
  transform: translateZ(0);
}

.g-header__lng {
  color: #c1c1b8;
  font-size: .8125rem;
  line-height: 1.153846153846154;
  display: none;
}

.g-header__lng__a {
  transition: color .3s;
}

.g-header__lng__a--en, .p-press-archive__media-select label select {
  margin: 0 0 0 1rem;
}

.g-header__lng__a.is-current {
  color: #0f0f10;
  pointer-events: none;
}

.is-invert .g-header__lng__a.is-current, .is-invert .g-header__lng__a:hover {
  color: #fff;
}

.g-header__lng__a:hover {
  color: #0f0f10;
}

.g-members {
  width: 14.5625rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform: translate3d(100%, 0, 0);
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.is-members-open .g-members {
  animation: gmbo .53s forwards;
}

.is-members-close .g-members {
  animation: gmbc .53s forwards;
}

.g-members__inner {
  min-height: 100%;
  background: #fff;
  padding: 1.25rem 1.25rem 1.875rem 1.875rem;
}

.g-members__header {
  display: flex;
  margin: 0 0 1.25rem;
  align-items: center;
  line-height: 1.5;
}

.g-members__header i {
  display: block;
  position: relative;
  width: .945rem;
  margin: 0 .6175rem 0 0;
}

.g-members__header i:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
}

.g-members__header p {
  flex: 1 1;
  padding: 0 .9375rem 0 0;
}

.g-members__close {
  display: block;
  position: relative;
  width: 1rem;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  cursor: pointer;
}

.g-members__close:before, .g-members__logo__inner:before,
.g-menu__close i:before, .g-menu__li--expand i:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  padding: 0 0 100%;
}

.g-members__close:hover {
  opacity: .7;
}

.g-members__logo {
  margin: 0 -1.25rem 2.09375rem -1.875rem;
  padding: 1.6875rem 1.1875rem 1.625rem 1.875rem;
  background: #f5f5f2;
}

.g-members__logo__inner {
  position: relative;
  width: 11.5rem;
  max-width: 100%;
}

.g-members__logo__inner:before {
  padding: 0 0 7.608695652173914%;
}

.g-members__ul .g-members__ul {
  margin-top: .96875rem;
  padding: 0 0 0 .625rem;
}

.g-members__li {
  display: block;
  cursor: pointer;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  font-size: .875rem;
  line-height: 1.5;
}

.g-members__li:hover {
  opacity: .7;
}

.g-members__li+.g-members__li {
  margin-top: 1rem;
}

.g-members__li .g-members__li {
  font-size: .8125rem;
  line-height: 1.769230769230769;
}

.g-members__li .g-members__li+.g-members__li {
  margin-top: .625rem;
}

.g-menu {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translate3d(-100%, 0, 0);
}

.g-menu__wrapper {
  display: flex;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.g-menu__inner {
  min-height: 100%;
  width: auto;
  flex: 1 1;
  background: #f5f5f2;
  padding: 1.875rem;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.g-menu__inner hr {
  margin: .71875rem 0;
}

.g-menu__li, .g-menu__sns a {
  display: block;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.g-menu__li {
  cursor: pointer;
  font-size: .875rem;
  line-height: 1.142857142857143;
  padding: .78125rem 0;
}

.g-menu__li:hover, .g-menu__sns a:hover {
  opacity: .7;
}

.g-menu__li.-logged-in {
  cursor: default;
  pointer-events: none;
}

.g-menu__li--expand {
  display: flex;
  align-items: center;
}

.g-menu__li--expand i {
  margin: 0 0 0 1.5rem;
  position: relative;
  width: .9375rem;
  background: url(/assets/menu/plus.svg) no-repeat 50% 50%;
  background-size: contain;
}

.g-menu__li--expand.is-expand {
  color: #c1c1b8;
}

.g-menu__li--expand.is-expand i {
  background-image: url(/assets/menu/minus.svg);
}

.g-menu__expand {
  height: 0;
  overflow: hidden;
  margin: 0 -1.875rem;
  transition-property: height, margin;
  transition-duration: .7s;
}

.g-menu__expand__inner {
  padding: 1.1875rem 1.875rem 1.1875rem 3.6875rem;
  border: solid #dddddb;
  border-width: 1px 0;
}

.g-menu__li.is-expand+.g-menu__expand {
  margin: 1.1875rem -1.875rem;
}

.g-menu__expand--desktop {
  display: none;
  width: 0;
}

.g-menu__logo, .g-menu__sns {
  margin: 1.625rem 0 0 .25rem;
  display: flex;
  align-items: center;
}

.g-menu__sns a {
  width: 1.5rem;
  height: 1.5rem;
}

.g-menu__sns a+a {
  margin: 0 0 0 2.375rem;
}

.g-menu__logo {
  margin: 0 0 2.5625rem;
  justify-content: space-between;
}

.g-menu__logo .c-logo {
  width: 4.3125rem;
}

.is-menu-open .g-menu {
  animation: gmo .73s forwards;
}

.is-menu-close .g-menu {
  animation: gmc .73s forwards;
}

.g-menu__close {
  width: 5rem;
  height: 100%;
  padding: 1.75rem 0 0;
}

.g-menu__close__btn {
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;
}

.g-menu__close__btn:hover {
  opacity: .7;
}

.g-menu__close i {
  display: block;
  position: relative;
  width: 1.25rem;
  margin: 0 0 .4375rem;
}

.g-menu__close p {
  color: #83827c;
  font-size: .6875rem;
  line-height: 1.272727272727273;
}

.g-menu__lng {
  color: #c1c1b8;
  font-size: .8125rem;
  line-height: 1.153846153846154;
  display: block;
  padding: 0 .625rem 0 0;
}

.g-menu__lng__a--en {
  margin: 0 0 0 1.875rem;
}

.g-menu__lng__a.is-current {
  color: #0f0f10;
  pointer-events: none;
}

.p-home__inner {
  background: #f5f5f2;
}

.p-home__section {
  padding: 6.25rem .9375rem;
}

.p-home__section__inner {
  max-width: 1840px;
  margin: 0 auto;
}

.p-home__section__footer {
  text-align: center;
}

.p-home__heading--1 {
  font-size: 1.5rem;
  line-height: 1.208333333333333;
  margin: 0 0 3.125rem;
  text-align: center;
}

header.p-home__heading--1 p {
  margin-top: .4375rem;
  font-size: 1.25rem;
  line-height: 1.3;
}

.p-home__heading--2 {
  font-size: 1.25rem;
  line-height: 1.25;
  margin: 0 0 1.875rem;
  text-align: center;
}

header.p-home__heading--2 p {
  margin-top: .53125rem;
  font-size: .8125rem;
  line-height: 1.230769230769231;
}

.p-home.is-intro {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.p-home.is-intro .c-headline, .p-home.is-intro .g-header {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

.p-home.is-header-show .c-headline, .p-home.is-header-show .g-header {
  animation: vbs3 1.2s forwards ease-out;
}

.p-home__intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 256;
}

.p-home__intro__ly {
  position: relative;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
      grid-row: 1/2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
      grid-column: 1/2;
  z-index: 0;
}

.p-home__intro__inner {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr;
  grid-template: 1fr/1fr;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.p-home__intro__bar {
  width: 100%;
  height: calc(100%/3 + 2px);
  margin: -1px 0;
  background: #000;
  transform: translate3d(100%, 0, 0);
}

.is-vs-0 .p-home__intro__bar {
  animation: vbs0 .33s forwards ease-in;
}

.is-vs-0 .p-home__intro__bar:nth-child(2) {
  animation-delay: .2s;
}

.is-vs-0 .p-home__intro__bar:nth-child(3) {
  animation-delay: .4s;
}

.is-vs-1 .p-home__intro__bar {
  transform: none;
  animation: vbs2 .33s forwards ease-out;
}

.is-vs-1 .p-home__intro__bar:nth-child(2) {
  animation-delay: .2s;
}

.is-vs-1 .p-home__intro__bar:nth-child(3) {
  animation-delay: .4s;
}

.p-home__intro__ly--0 {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.p-home__intro__ly--0 .c-logo {
  width: 11rem;
  transform: translate(-3%, 0);
  opacity: 0;
}

.is-prepared .p-home__intro__ly--0 .c-logo {
  opacity: 1;
  animation: fade-in .73s both .4s;
}

.is-vs-1 .p-home__intro__ly--0 {
  display: none;
}

.p-home__intro__ly--1 {
  z-index: 3;
  display: flex;
  flex-flow: column;
}

.p-home__intro__mk {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  z-index: 4;
}

.is-vs-0 .p-home__intro__mk {
  animation: vbs1 .33s forwards .2s ease-in;
}

.is-vs-1 .p-home__intro__mk {
  width: 100%;
}

.p-home__intro__mk__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
}

.p-home__intro__mk .c-logo {
  width: 11rem;
  transform: translate(-3%, 0);
}

.is-vs-1 .p-home__intro__mk .c-logo {
  animation: fade-in .93s both linear reverse;
}

.p-home__intro__scroll {
  position: absolute;
  z-index: 2;
  right: 1.25rem;
  bottom: 1.25rem;
  color: #fff;
}

.p-home__intro__scroll span, .p-home__main__scroll span {
  font-size: .6875rem;
  letter-spacing: 0;
  line-height: 1.090909090909091;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-feature-settings: 'pkna' 1;
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
}

.p-home__intro__scroll span:after, .p-home__main__scroll span:after {
  content: '';
  height: 3.75rem;
  width: 1px;
  background: #fff;
  margin: .625rem .0625rem 3.75rem 0;
  animation: vbs4 1.1s both ease-in-out infinite;
}

.p-home__swiper {
  background: #000;
  padding: 4.0625rem 0;
}

.p-home__swiper__image {
  display: block;
  position: relative;
}

.p-home__swiper__image img, .p-home__swiper__navigation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-home__swiper__navigation {
  pointer-events: none;
  display: none;
  z-index: 1;
}

.p-home__swiper__arrow {
  background: rgba(133, 152, 172, .3) no-repeat 50% 50%;
  background-size: 1.0075rem;
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: calc(50% - 2rem);
  pointer-events: auto;
  cursor: pointer;
  transition: background-color .3s ease-in-out;
}

.p-home__swiper__arrow:hover {
  background-color: rgba(133, 152, 172, .5);
}

.p-home__swiper__arrow.-next {
  right: 4.166666666666666vw;
  background-image: url(/assets/ui/slide_arrow_next.svg);
}

.p-home__swiper__arrow.-prev {
  left: 4.166666666666666vw;
  background-image: url(/assets/ui/slide_arrow_back.svg);
}

.p-home__swiper__pagination {
  position: absolute;
  bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.p-home__swiper__pagination .swiper-pagination-bullet {
  width: 1.125rem;
  height: 1.125rem;
  padding: .1875rem;
  margin: 0 .375rem;
  background: 0 0;
  border-radius: 0;
  outline: 0;
  opacity: 1;
}

.p-home__swiper__pagination .swiper-pagination-bullet:before {
  content: '';
  display: block;
  width: .75rem;
  height: .75rem;
  background: #ebebeb;
  transition: background-color .5s;
  border-radius: 50%;
}

.p-home__swiper__pagination .swiper-pagination-bullet-active:before,
.p-home__swiper__pagination .swiper-pagination-bullet:hover:before {
  background: #a7a7a7;
}

.p-home__main {
  width: 100%;
  overflow: hidden;
  margin-top: -3.625rem;
  visibility: hidden;
  position: relative;
}

.p-home__main__inner {
  position: relative;
  z-index: 0;
}

.p-home__main__scroll {
  position: absolute;
  z-index: 16;
  right: 1.25rem;
  bottom: 1.25rem;
  color: #fff;
  pointer-events: none;
}

.p-home__main__scroll span:after {
  animation: main-scroll 1.1s both ease-in-out infinite;
}

.is-prepared .p-home__main {
  visibility: visible;
}

.p-home__about {
  padding: 0;
  position: relative;
  z-index: 1;
}

.p-home__about__wrapper {
  background: #e0e0dc;
  padding: 3.4375rem .875rem 6.25rem;
}

.p-home__about .p-home__heading--2 {
  margin: 0 0 2.59375rem;
  opacity: 0;
  font-size: 1.625rem;
  line-height: 1.230769230769231;
}

.p-home__about .p-home__heading--2 p {
  margin-top: .65625rem;
}

.p-home__about__text {
  opacity: 0;
  line-height: 2;
}

.p-home__about h3 {
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.7;
  margin: -.4375rem 0 1.125rem;
  text-align: center;
}

.p-home__about footer {
  opacity: 0;
  margin: 2.5rem 0 0;
  text-align: center;
}

.p-home__about.is-show .p-home__heading--2 {
  animation: iv .7s forwards ease-in-out;
}

.p-home__about.is-show .p-home__about__text {
  animation: iv .7s forwards .4s ease-in-out;
}

.p-home__about.is-show footer {
  animation: iv .7s forwards .8s ease-in-out;
}

.p-home__lineup {
  padding: 6.25rem 0;
}

.p-home__lineup .p-home__heading--1 {
  margin: 0 0 -1.3125rem;
  position: relative;
  z-index: 3;
  font-size: 1.5rem;
  line-height: 1.333333333333333;
}

.p-home__lineup__wrapper {
  background: #fff;
  padding: 5.625rem .9375rem 4.375rem;
}

.p-home__lineup__sct+.p-home__lineup__sct {
  margin-top: 3.75rem;
}

.p-home__lineup__sct__heading {
  display: flex;
  justify-content: center;
  margin: 0 0 2.5625rem;
  opacity: 0;
}

.p-home__lineup__sct__heading__inner {
  display: inline-flex;
  flex-flow: column;
  text-align: center;
}

.p-home__lineup__sct__heading__inner:before {
  content: '';
  display: block;
  height: 1px;
  background: #000;
  width: 0;
}

.p-home__lineup__sct__heading h3 {
  font-weight: 300;
  margin: .6875rem 0 0;
}

.p-home__lineup__sct__inner {
  display: flex;
  flex-flow: wrap;
  margin: 0 0 0 -2.608695652173913%;
}

.p-home__lineup__sct--material .p-home__lineup__a {
  font-size: .75rem;
  line-height: 1.666666666666667;
}

.p-home__lineup__sct--shape .p-home__lineup__a {
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  height: 2.8125rem;
  padding: 0 0 0 2.824858757062147%;
}

.p-home__lineup__sct--shape .p-home__lineup__a__i {
  flex: 1 1;
  margin: 0;
}

.p-home__lineup__sct--shape .p-home__lineup__a--0 .p-home__lineup__a__i:before {
  padding: 0 0 20.43010752688172%;
}

.p-home__lineup__sct--shape .p-home__lineup__a--1 .p-home__lineup__a__i:before {
  padding: 0 0 37.634408602150536%;
}

.p-home__lineup__sct--shape .p-home__lineup__a--2 .p-home__lineup__a__i:before,
.p-home__lineup__sct--shape .p-home__lineup__a--3 .p-home__lineup__a__i:before,
.p-home__lineup__sct--shape .p-home__lineup__a--4 .p-home__lineup__a__i:before {
  padding: 0 0 30.107526881720432%;
}

.p-home__lineup__sct--shape .p-home__lineup__a--5 .p-home__lineup__a__i:before {
  padding: 0 0 37.83763440860215%;
}

.p-home__lineup__sct--shape .p-home__lineup__a--6 .p-home__lineup__a__i:before {
  padding: 0 0 22.58064516129032%;
}

.p-home__lineup__sct--shape .p-home__lineup__a--7 .p-home__lineup__a__i:before {
  padding: 0 0 34.40860215053764%;
}

.p-home__lineup__sct--shape .p-home__lineup__a--8 .p-home__lineup__a__i:before {
  padding: 0 0 27.956989247311824%;
}

.p-home__lineup__sct--shape .p-home__lineup__a p {
  width: 61.30952380952381%;
  padding: 0 0 0 8.333333333333332%;
}

.p-home__lineup__sct img {
  object-fit: contain;
}

.p-home__lineup__sct.is-show .p-home__lineup__sct__heading {
  animation: iv .7s forwards ease-in-out;
}

.p-home__lineup__sct.is-show .p-home__lineup__sct__heading__inner:before {
  animation: iv-hl-hr .7s forwards .4s ease-in-out;
}

.p-home__lineup__a {
  width: 47.45762711864407%;
  border-radius: .25rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddb;
  margin: 0 0 0 2.542372881355933%;
  transition: background-color .3s;
  font-size: .8125rem;
  line-height: 1.769230769230769;
  padding: 5.649717514124294% 0 4.519774011299435%;
  opacity: 0;
}

.p-home__lineup__a:hover {
  background: #f5f5f2;
}

.p-home__lineup__a:nth-child(n+3) {
  margin-top: 2.542372881355933%;
}

.p-home__lineup__a__i {
  position: relative;
  width: 75.59523809523809%;
  margin: 0 0 2.976190476190476%;
  mix-blend-mode: multiply;
}

.p-home__lineup__a__i:before, .p-home__products__logo:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  padding: 0 0 35.43307086614173%;
}

.p-home__lineup__a__i img, .p-home__products__logo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.p-home__lineup__a.is-show {
  animation: iv-hl-img .2s forwards;
}

.p-home__products {
  padding: 3.125rem 0 6.25rem;
}

.p-home__products .p-home__heading--1 {
  margin: 0 0 -1rem;
  position: relative;
  z-index: 3;
  font-size: 1.625rem;
  line-height: 1.230769230769231;
}

.p-home__products__logo {
  position: relative;
  margin: 0 auto;
}

.p-home__products__logo:before {
  padding: 0 0 100%;
}

.p-home__products__li {
  font-size: .875rem;
  line-height: 1.714285714285714;
  display: block;
}

.p-home__products__li .c-iv-i:before {
  padding: 0 0 92.53333333333333%;
}

.p-home__products__li .c-iv-i__ly {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 0;
  text-align: center;
}

.p-home__products__li .c-iv-i__t {
  width: 100%;
}

.p-home__products__li b {
  display: block;
  line-height: 1.142857142857143;
  margin: 0 0 .5rem;
}

.p-home__products__li--9999 .c-iv-i:before {
  padding: 0 0 129.06666666666666%;
}

.p-home__products__li--9999 .c-iv-i__ly {
  color: #fff;
  padding: 5.5rem 0 4.625rem;
}

.p-home__products__li--9999 b, .p-home__products__li--feelsun b {
  color: #7e9ca0;
}

.p-home__products__li--9999 .p-home__products__logo {
  width: 7.5rem;
}

.p-home__products__li--9999 .p-home__products__logo:before {
  padding: 0 0 55.96433304134941%;
}

.p-home__products__li--feelsun .p-home__products__logo {
  width: 14.5625rem;
}

.p-home__products__li--feelsun .p-home__products__logo:before {
  padding: 0 0 19.714285714285715%;
}

.p-home__products__li--plaide, .p-home__products__li--worke {
  color: #fff;
}

.p-home__products__li--plaide .c-iv-i__ly {
  padding-bottom: 1.875rem;
}

.p-home__products__li--plaide b {
  color: #b264b5;
}

.p-home__products__li--plaide .p-home__products__logo {
  width: 10.375rem;
}

.p-home__products__li--plaide .p-home__products__logo:before {
  padding: 0 0 15.126050420168067%;
}

.p-home__products__li--worke .c-iv-i__ly {
  padding-bottom: 3.125rem;
}

.p-home__products__li--worke b {
  color: #b5ae54;
}

.p-home__products__li--worke .p-home__products__logo {
  width: 10.6875rem;
}

.p-home__products__li--worke .p-home__products__logo:before {
  padding: 0 0 8.77742946708464%;
}

.p-home__ec {
  padding: 0 0 3.125rem;
}

.p-home__ec .c-iv-i:before {
  padding: 0 0 78.93333333333334%;
}

.p-home__ec__inner {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: url(/assets/home/ec/arrow.svg) no-repeat bottom 3.75rem left 50%;
  background-size: 25.6%;
}

.p-home__ec .p-home__heading--2 {
  color: #fff;
  font-size: 1.625rem;
  line-height: 1.230769230769231;
  letter-spacing: .05em;
}

.p-home__collections {
  padding: 3.125rem 0;
}

.p-home__collections .p-home__heading--1, .p-home__journal .p-home__heading--1 {
  margin: 0 0 3.125rem;
}

.p-home__collections__wrapper {
  margin: 0 0 2.5rem;
}

.p-home__collections__li {
  display: block;
  font-size: .6875rem;
  line-height: 1.090909090909091;
}

.p-home__collections__li p {
  margin: .75rem 0 0;
  text-align: center;
}

.p-home__collections__li .c-iv-i:before {
  padding: 0 0 72.1311475409836%;
}

.p-home__journal {
  padding: 3.125rem 0;
}

.p-home__support {
  padding: 3.125rem .625rem;
}

.p-home__support__heading {
  margin: 0 0 2.78125rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.6875;
}

.p-home__support__button {
  justify-content: flex-start;
  height: auto;
}

.p-home__support__button+.p-home__support__button {
  margin: .9375rem 0 0;
}

.p-home__support__button .c-sans {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 0 .3125em;
}

.p-home__support__button p {
  font-size: .6875rem;
  line-height: 1.545454545454545;
}

.p-home__support__button__i {
  width: 21.73913043478261%;
  height: 0;
  padding: 0 0 24.637681159420293%;
  margin: 0 4.347826086956522% 0 0;
  background: no-repeat 50% 50%;
  background-size: cover;
}

.p-home__support__button__i--0 {
  background-image: url(/assets/home/support/0.png);
}

.p-home__support__button__i--1 {
  background-image: url(/assets/home/support/1.png);
}

.p-home__information {
  padding: 3.125rem .9375rem 6.25rem;
}

.p-home__information .p-home__heading--2 {
  margin: 0 0 3.03125rem;
}

.p-home__shop {
  padding: 0 0 10rem;
}

.p-home__shop .c-iv-i:before {
  padding: 0 0 108.26666666666667%;
}

.p-home__shop__inner {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: .625rem;
}

.p-home__shop .p-home__heading--2 {
  color: #fff;
  margin: 0 0 2.40625rem;
}

.p-home__shop__buttons {
  width: 100%;
}

.p-home__shop__buttons p:nth-child(2) {
  margin: .75rem 0 0;
}

.p-informations .c-heading--1 {
  margin: 0 0 3.75rem;
}

.p-informations .c-tabs {
  margin-bottom: 2.6875rem;
}

.p-informations__li {
  border-bottom: 1px solid #dddddb;
  display: flex;
  align-items: center;
  padding: 0 0 1.25rem;
}

.p-informations__li+.p-informations__li {
  margin-top: 1.25rem;
}

.p-informations__li:hover .p-informations__li__inner,
.p-store-top__swiper a:hover {
  opacity: .7;
}

.p-informations__li__inner {
  flex: 1 1;
  transition: opacity .3s;
}

.p-informations__li__thumb {
  max-width: 7.5rem;
  min-width: 5rem;
  width: 15%;
  margin: 0 0 0 1.25rem;
}

.p-informations__li__thumb .c-iv-i:before {
  padding: 0 0 66.66666666666666%;
}

.p-information__meta {
  line-height: 1.153846153846154;
  margin: 0 0 .425rem;
}

.p-information__meta__tag {
  color: #a7a7a7;
  margin: 0 0 0 .75rem;
}

.p-store-top__li {
  max-width: 800px;
  margin: 0 auto;
}

.p-store-top__li .c-iv-i:before {
  padding: 0 0 62.5%;
}

.p-store-top__li+.p-store-top__li {
  margin-top: 5.3rem;
}

.p-store-top__li__label {
  display: flex;
  align-items: baseline;
  font-size: 1.375rem;
  line-height: 1.063636363636363;
  margin: 1.875rem 0 .6125rem;
  background: url(/assets/ui/arrow-right.svg) no-repeat 100% 55%;
  background-size: .9375rem;
}

.p-store-top__li__label small {
  font-size: .5em;
  margin: 0 0 0 1.090909090909091em;
}

.p-store-top__footer {
  border: 1px solid #dddddb;
  margin: 7.5rem 0 0;
  padding: 1.875rem 1.25rem;
  text-align: center;
}

.p-store-top__footer__inner {
  margin: 1.55rem 0 0;
}

.p-store-top__footer .c-button {
  margin: 0;
}

.p-store-top__footer .c-button+.c-button {
  margin: .625rem 0 0;
}

.p-store-top__swiper {
  margin: 0 -1.25rem 7.5rem;
  position: relative;
}

.p-store-top__swiper .c-pic:before {
  padding: 0 0 75%;
}

.p-store-top__swiper a {
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.p-store-top__swiper__pagination {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.p-store-top__swiper__pagination .swiper-pagination-bullet {
  width: .875rem;
  height: .875rem;
  padding: .375rem;
  margin: 0;
  background: 0 0;
  border-radius: 0;
  outline: 0;
  opacity: 1;
}

.p-store-top__swiper__pagination .swiper-pagination-bullet:before {
  content: '';
  display: block;
  width: .375rem;
  height: .375rem;
  background: #f5f5f2;
  transition: background-color .5s;
  border-radius: 50%;
}

.p-store-top__swiper__pagination .swiper-pagination-bullet-active:before {
  background: #a7a7a7;
}

.p-store-guide__tabs a {
  display: flex;
  flex-flow: column;
}

.p-store-guide__tabs a:after {
  content: '';
  width: .6875rem;
  height: .3125rem;
  background: url(/assets/faq/arrow.svg) no-repeat 50% 50%;
  background-size: contain;
  margin: .675rem auto 0;
  transition-property: opacity;
  transition-duration: .3s;
}

.p-store-guide__payment {
  flex-flow: row;
  justify-content: space-between;
}

.p-store-guide__payment__fig:before {
  padding: 0 0 62.142857142857146%;
}

.p-store-guide__payment .c-row--l {
  width: 65.67164179104478%;
}

.p-store-guide__payment .c-row--r {
  width: 29.850746268656714%;
  margin: 0;
}

.p-store-guide__payment--card {
  margin: 0 0 1.55rem;
  max-width: 354px;
}

.p-store-guide__payment--card__fig:before {
  padding: 0 0 23.728813559322035%;
}

.p-store-guide__size {
  background: #fff;
  padding: 1.25rem;
  margin: 2.8rem 0 0;
}

.p-store-guide__size__fig:before {
  padding: 0 0 141.66666666666669%;
}

.p-parts-and-size__inner {
  background: #fff;
}

.p-parts-and-size__fig {
  padding: .625rem 1.25rem 1.875rem;
}

.p-parts-and-size__pic:before {
  padding: 0 0 86.92307692307692%;
}

.p-press-archive__wrapper {
  display: flex;
  flex-flow: wrap;
}

.p-press-archive .c-heading--1 {
  margin: 0 0 3.75rem;
}

.p-press-archive .c-tabs {
  margin-bottom: 2.6875rem;
}

.p-press-archive__li .c-iv-i:before {
  padding: 0 0 74.58333333333333%;
}

.p-press-archive__li.is-pdf figcaption p:after {
  content: '';
  background: url(/assets/ui/icons/pdf.svg) no-repeat 50% 50%;
  background-size: contain;
  width: .769230769230769em;
  height: .923076923076923em;
  display: inline-block;
  vertical-align: -.076923076923077em;
  margin: 0 0 0 .307692307692308em;
}

.p-press-archive__meta {
  line-height: 1.153846153846154;
  margin: 0 0 .425rem;
}

.p-press-archive__meta__tag {
  color: #a7a7a7;
  margin: 0 0 0 .75rem;
}

.p-press-archive__media-select {
  margin: 0 0 2.5rem;
}

.p-press-archive__media-select label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.p-reserve-day__header {
  position: relative;
  height: 14.625rem;
}

.p-reserve-day__header__ly {
  position: relative;
  height: 100%;
  z-index: 1;
}

.p-reserve-day__header__inner {
  background: #fff;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.p-reserve-day__header__bg {
  z-index: 0;
}

.p-reserve-day__header__bg, .p-reserve-day__header__bg img,
.p-shop__fns__header img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-reserve-day__header__fig--0 {
  width: 76.71822916666667%;
}

.p-reserve-day__header__fig--0:before {
  padding: 0 0 5.558421985213749%;
}

.p-reserve-day__header__fig--1 {
  width: 53.385416666666664%;
}

.p-reserve-day__header__fig--1:before {
  padding: 0 0 22.439024390243905%;
}

.p-reserve-day__header hr {
  width: 76.71822916666667%;
  margin: 3.385416666666667% 0 5.875%;
}

.p-reserve-day__reserve {
  margin: 3.75rem 0;
}

.p-reserve-day__reserve__inner {
  border: solid #dddddb;
  border-width: 1px 0;
  padding: 1.65625rem .3125rem;
  text-align: center;
}

.p-reserve-day__reserve__heading {
  font-size: .875rem;
  line-height: 1.5;
  font-weight: 600;
  margin: 0 0 1.33125rem;
}

.p-reserve-day__reserve__name {
  margin: 0 0 .175rem;
}

.p-reserve-day__reserve__tel {
  margin: 0 0 .34375rem;
}

.p-reserve-day__reserve__tel p:nth-child(2) {
  font-size: 2.0625rem;
  line-height: 1.303030303030303;
  margin: 0 0 0 .393939393939394em;
}

.p-reserve-day__reserve__time {
  color: #a7a7a7;
}

.p-reserve-day__reserve__btn {
  margin-top: 2.34375rem;
}

.p-reserve-day__reserve__footer {
  margin: 2.8rem 0 0;
}

.p-reserve-day__scene+.p-reserve-day__scene {
  margin: 5rem 0 0;
}

.p-reserve-day__scene__i {
  width: 93.33333333333333%;
  margin: 0 0 0 -.625rem;
}

.p-reserve-day__scene__t {
  flex: 1 1;
  background: #e5e5d9;
  padding: 3.125rem 1.875rem 1.875rem 3.125rem;
  margin: -1.25rem -1.25rem 0;
}

.p-reserve-day__scene--reverse .p-reserve-day__scene__t {
  padding: 3.125rem 3.125rem 1.875rem 1.875rem;
}

.p-reserve-day__scene__num {
  margin: -23.728813559322035% -.625rem 6.779661016949152% auto;
}

.p-reserve-day__scene__num--1 {
  width: 43.51593220338984%;
}

.p-reserve-day__scene__num--1:before {
  padding: 0 0 69.37805752033152%;
}

.p-reserve-day__scene__num--2 {
  width: 49.31152542372882%;
}

.p-reserve-day__scene__num--2:before {
  padding: 0 0 61.301720641511245%;
}

.p-reserve-day__scene__num--3 {
  width: 47.920338983050854%;
}

.p-reserve-day__scene__num--3:before {
  padding: 0 0 64.43179004704135%;
}

.p-reserve-day__scene__heading {
  font-size: 1.25rem;
  line-height: 1.75;
  margin: 0 0 .70625rem;
}

.p-reserve-day__scene .c-iv-i {
  background: #c1c1b8;
}

.p-reserve-day__scene .c-iv-i:before {
  padding: 0 0 125%;
}

.p-reserve-day__flow {
  padding: 0 0 0 1.25rem;
}

.p-reserve-day__flow+.p-reserve-day__flow {
  margin: 5.625rem 0 0;
}

.p-reserve-day__flow__i {
  position: relative;
}

.p-reserve-day__flow__i:before {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 1;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 0 0 100% 0;
  background: #f5f5f2;
  border: solid #a7a7a7;
  border-width: 0 1px 1px 0;
}

.p-reserve-day__flow__fig:before {
  padding: 0 0 67.85714285714286%;
}

.p-reserve-day__flow__step {
  position: absolute;
  width: 40%;
  z-index: 2;
  margin: -20% 0 0 -20%;
  color: #c1c1b8;
}

.p-reserve-day__flow__step:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  padding: 0 0 100%;
}

.p-reserve-day__flow__step__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-flow: column;
  font-size: 2.625rem;
  line-height: 1.071428571428571;
}

.p-reserve-day__flow__step__inner span {
  font-size: .30952380952381em;
  margin: 0 0 .384615384615385em;
}

.p-reserve-day__flow__t {
  margin: .96875rem 0 0;
}

.p-reserve-day__flow__t h3 {
  margin-top: 0;
  font-weight: 600;
}

.p-reserve-day__flow__t .c-button {
  margin: 1.5625rem 0 0;
}

.p-reserve-day__flow__t p>a, .p-shop__detail__ln a {
  text-decoration: underline;
}

.p-reserve-day__flow__t p>a:hover {
  text-decoration: none;
}

.p-reserve-day__notice ul {
  margin: 0 0 1.39375rem;
}

.p-reserve-day__shop-info {
  background: #fff;
  margin: 5rem 0;
  padding: 1.25rem;
}

.p-reserve-day__shop-info__heading {
  text-align: center;
  margin: 0 0 2.46875rem;
}

.p-reserve-day__shop-info__name {
  margin: 0 0 .70625rem;
}

.p-reserve-day__shop-info p+p {
  margin: 1.225rem 0 0;
}

.p-reserve-day__shop-info b {
  display: block;
  margin: 0 0 .35rem;
}

.p-reserve-day__shop-info__fig:before {
  padding: 0 0 73.46153846153847%;
}

.p-reserve-day__footer {
  margin: 3.425rem 0 0;
}

.p-service-shop__heading {
  margin: 0 0 2.8rem;
}

.p-shop__wrapper .c-heading--1 {
  margin-bottom: 1.875rem;
}

.p-shop__inner {
  margin: 0 -.625rem;
}

.p-shop__aside {
  margin: 3.75rem auto 0;
  max-width: 800px;
}

.p-shop__aside__ln .c-iv-i:before {
  padding: 0 0 26.31578947368421%;
}

.p-shop__aside__banner {
  margin: 3.425rem 0 0;
}

.p-shop__aside__banner .c-iv-i:before {
  padding: 0 0 29.125%;
}

.p-shop__fns-ln {
  margin: 5rem 0 0;
  text-align: center;
  border: solid #dddddb;
  border-width: 1px 0;
  padding: 2.28125rem 0 2.5rem;
}

.p-shop__fns-ln p {
  margin: 0 0 1.65625rem;
}

.p-shop__tag {
  font-size: .6875rem;
  line-height: 1.090909090909091;
  color: #fff;
  background: #83827c;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: .25rem .375rem;
  margin: 0 0 -.09375rem;
}

.p-shop__tab, .p-shop__tabs {
  display: flex;
  justify-content: center;
}

.p-shop__tab {
  width: 9.375rem;
  height: 5.625rem;
  margin: 0 0 -1px;
  font-size: .875rem;
  letter-spacing: 0;
  line-height: 1.142857142857143;
  text-align: center;
  align-items: center;
  flex-flow: column;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.p-shop__tabs {
  border-bottom: 1px solid #dddddb;
  margin: 0 -1.25rem;
}

.p-shop__loc__map__info__close:hover i, .p-shop__tab:hover {
  opacity: .7;
}

.p-shop__tab.is-current {
  border: solid #dddddb;
  border-width: 1px 1px 0;
  background: #f5f5f2;
  pointer-events: none;
}

.p-shop__tab__icon {
  display: block;
  width: .9375rem;
  height: .9375rem;
  background: no-repeat 50% 50%;
  background-size: contain;
  margin: 0 auto .625rem;
}

.p-shop__tab__icon--location {
  background-image: url(/assets/shop/location.svg);
}

.p-shop__tab__icon--list {
  background-image: url(/assets/shop/list.svg);
}

.p-shop__tab__icon--fns {
  background-image: url(/assets/ui/logo.svg);
  width: 1.6875rem;
}

.p-shop__loc__form {
  display: flex;
  border-bottom: 1px solid #000;
}

.p-shop__loc__form button, .p-shop__loc__form input {
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  outline: 0;
}

.p-shop__loc__form input {
  border-radius: 0;
  flex: 1 1;
  padding: 2rem 0 2rem .625rem;
  font-size: .8125rem;
  line-height: 1.230769230769231;
  background: 0 0;
}

.p-shop__loc__form button {
  width: 2.75rem;
  background: url(/assets/ui/roupe.svg) no-repeat top 50% right .9375rem;
  background-size: .875rem;
}

.p-shop__loc__info {
  margin: 1.875rem 0 0;
}

.p-shop__loc__info .c-button {
  width: 100%;
}

.p-shop__loc__map__wrapper {
  margin: 3.75rem -.625rem 0;
}

.p-shop__loc__map__wrapper .c-map {
  position: relative;
}

.p-shop__loc__map__wrapper .c-map:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  padding: 0 0 157.33333333333331%;
}

.p-shop__loc__map__info {
  position: relative;
  background: #fff;
}

.p-shop__loc__map__info__inner {
  padding: 3.125rem 2.375rem;
}

.p-shop__loc__map__info__name {
  font-size: 1rem;
  line-height: 1.6875;
}

.p-shop__loc__map__info__fig {
  margin: 1.53125rem 0 0;
}

.p-shop__loc__map__info__fig:before {
  padding: 0 0 60%;
}

.p-shop__loc__map__info__desc {
  margin: 1.55rem 0 0;
}

.p-shop__loc__map__info__note {
  margin: .6rem 0 0;
  color: #a7a7a7;
}

.p-shop__loc__map__info__items {
  margin: 1.55rem 0 0;
}

.p-shop__detail__items p+p, .p-shop__loc__map__info__items p+p {
  margin: .35rem 0 0;
}

.p-shop__loc__map__info__ln {
  margin: 2.8rem 0 0;
}

.p-shop__list__filter__products select, .p-shop__loc__map__info__btn {
  width: 100%;
}

.p-shop__loc__map__info__btn+.p-shop__loc__map__info__btn {
  margin-top: 1.875rem;
}

.p-shop__loc__map__info__close {
  position: absolute;
  right: 0;
  top: -3.75rem;
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
}

.p-shop__loc__map__info__close i {
  width: 1rem;
  transition: opacity .7s;
}

.p-shop__list {
  margin: 3.75rem 0 0;
}

.p-shop__list__side .c-toggle {
  border: solid #dddddb;
  border-width: 1px 0;
}

.p-shop__list__side .c-toggle.is-open {
  border-width: 1px 0 0;
}

.p-shop__list__side .c-toggle__inner {
  padding: 0;
  display: flex;
  flex-flow: wrap;
}

.p-shop__list__side .c-toggle.is-open .c-toggle__inner {
  display: flex;
}

.p-shop__list__side__a {
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.p-shop__list__side__a:hover {
  opacity: .7;
}

.p-shop__list__side__a hr {
  display: none;
}

.p-shop__list__inner {
  margin: 3.75rem 0 0;
}

.p-shop__list__inner>:first-child {
  margin-top: 0;
}

.p-shop__list__block+.p-shop__list__block {
  margin-top: .125rem;
}

.p-shop__list__filter__products p {
  margin: 0 0 .425rem;
}

.p-shop__list__filter__brands {
  margin: 1.55rem 0 0;
}

.p-shop__list__filter__brands a+a {
  margin: 0 0 0 1.625rem;
}

.p-shop__detail__inner {
  padding: 0 0 3.25rem;
}

.p-shop__detail .c-heading--1 {
  margin-bottom: 1.875rem;
}

.p-shop__detail .p-shop__tag {
  margin: 0 0 .03125rem;
}

.p-shop__detail__name {
  font-size: 1.125rem;
  line-height: 1.5;
}

.p-shop__detail__desc {
  margin: 1.90625rem 0 0;
}

.p-shop__detail__note {
  color: #a7a7a7;
  margin: .6rem 0 0;
}

.p-shop__detail__items {
  margin: .6rem 0 0;
}

.p-shop__detail__ln {
  margin: 1.225rem 0 0;
}

.p-shop__detail__banner {
  display: block;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  margin: 1rem 0 0;
}

.p-shop__detail__banners {
  margin: 2.175rem 0 0;
}

.p-shop__detail__banner:hover {
  opacity: .7;
}

.p-shop__detail__banner__fig:before {
  padding: 0 0 25%;
}

.p-shop__detail__map {
  margin: 0 -1.25rem;
}

.p-shop__detail__map .c-map {
  position: relative;
}

.p-shop__detail__map .c-map:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  padding: 0 0 55.46666666666666%;
}

.p-shop__detail__aside {
  border: solid #dddddb;
  border-width: 1px 0;
  margin: 5rem 0 0;
  padding: 2.5rem 0;
}

.p-shop__detail__aside__heading {
  margin: 0 0 1.85rem;
}

.p-shop__detail__aside__li+.p-shop__detail__aside__li {
  margin: 1.225rem 0 0;
}

.p-shop__detail__shop-info {
  margin: 5rem 0 0;
}

.p-shop__detail__shop-info__inner {
  margin: 2rem 0 0;
}

.p-shop__detail__shop-info .c-heading--3 {
  margin-top: 0;
}

.p-shop__detail__tax-free__fig {
  width: 11.875rem;
  margin: 2.175rem 0 0;
}

.p-shop__detail__tax-free__fig:before {
  padding: 0 0 25.075528700906347%;
}

.p-shop__detail__swiper__fig:before {
  padding: 0 0 52.459016393442624%;
}

.p-shop__detail__swiper .swiper-container {
  margin: 0 -1.25rem;
}

.p-shop__detail__swiper__thumbnail {
  width: calc((100% - .9375rem)/5);
  margin: 3px 0 0 3px;
  cursor: pointer;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.p-shop__detail__swiper__thumbnails {
  display: flex;
  flex-flow: wrap;
  margin: 1rem 0 0 -3px;
}

.p-shop__detail__swiper__thumbnail:hover {
  opacity: .7;
}

.p-shop__detail__swiper__thumbnail__fig:before {
  padding: 0 0 49.152542372881356%;
}

.p-shop__detail__swiper__thumbnail.is-current {
  border: 2px solid #a7a7a7;
  pointer-events: none;
}

.p-shop__detail__footer {
  margin: 3.425rem 0 0;
}

.p-shop__fns__header {
  position: relative;
  height: 12.5rem;
}

.p-shop__fns-list__footer {
  padding: 3.125rem 0 0;
}

.p-shop__fns__sct img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
}

.p-shop__fns__sct+.p-shop__fns__sct {
  margin: 5rem 0 0;
}

.p-shop__fns__sct h3 {
  font-weight: 300;
}

.p-shop__fns__sct__ln {
  margin-top: 2.3rem;
}

.p-shop__fns__sct__ln .c-ln--more {
  width: 8.75rem;
}

.p-shop__fns__mark {
  background: #fff;
  padding: 1.25rem;
  margin: 3.75rem 0 0;
}

.p-shop__fns__mark__fig {
  width: 3.75rem;
}

.p-shop__fns__mark__fig:before {
  padding: 0 0 37.81513748979036%;
}

.p-shop__fns__mark p {
  margin: 1em 0 0;
  color: #a7a7a7;
}

.p-shop__fns__aside {
  margin: 6.25rem auto 0;
}

.p-site-policy__download a:nth-of-type(n+2) {
  margin-top: .4125rem;
}

.p-spirit__home {
  padding: 2.375rem 0 7.5rem;
}

.p-spirit__home .c-heading--1 {
  margin: 0 0 2.175rem;
}

.p-spirit__home__inner {
  padding: 2.5rem 1.25rem;
}

.p-spirit__home .c-lead:last-child {
  margin: 0;
}

.p-spirit__home__nav {
  background: #ebebeb;
}

.p-spirit__home__nav__inner {
  margin-top: 34.8;
}

.p-spirit__home__nav h3 {
  text-align: center;
  margin: 0 0 1.65625rem;
}

.p-spirit__home__nav hr {
  margin: 2.5rem 0 2.28125rem;
}

.p-spirit__home__nav__athlete {
  display: block;
  color: #fff;
}

.p-spirit__home__nav__athlete .c-iv-i {
  background: #ebebeb;
  z-index: 0;
}

.p-spirit__home__nav__athlete .c-iv-i:before {
  padding: 0 0 37%;
}

.p-spirit__home__nav__athlete .c-iv-i__t {
  height: 100%;
}

.p-spirit__home__nav__athlete .c-iv-i__bg:before {
  content: '';
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .7;
  transition: opacity .3s;
}

.p-spirit__home__nav__athlete:hover .c-iv-i__bg:before {
  opacity: .33;
}

.p-spirit__home__nav__button {
  color: #fff;
  background: #c1c1b8 url(/assets/ui/blank.svg) no-repeat top 50% right 1.25rem;
  background-size: .75rem;
  justify-content: flex-start;
  padding: 0 0 0 1.625rem;
}

.p-spirit__home__nav__button:before {
  content: '';
  display: block;
  width: 1.4375rem;
  height: 1.4375rem;
  background: no-repeat 50% 50%;
  background-size: contain;
  margin: 0 .75rem .125rem 0;
}

.p-spirit__home__nav__button--fb:before {
  background-image: url(/assets/ui/fb.w.svg);
}

.p-spirit__home__nav__button--ig:before {
  background-image: url(/assets/ui/ig.w.svg);
}

.p-spirit__swiper {
  position: relative;
}

.p-spirit__swiper__pagination {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.p-spirit__swiper__pagination .swiper-pagination-bullet {
  width: .875rem;
  height: .875rem;
  padding: .375rem;
  margin: 0;
  background: 0 0;
  border-radius: 0;
  outline: 0;
  opacity: 1;
}

.p-spirit__swiper__pagination .swiper-pagination-bullet:before {
  content: '';
  display: block;
  width: .375rem;
  height: .375rem;
  background: #f5f5f2;
  transition: background-color .5s;
  border-radius: 50%;
}

.p-spirit__swiper__pagination .swiper-pagination-bullet-active:before {
  background: #a7a7a7;
}

.p-spirit__swiper__slide:before {
  padding: 0 0 45%;
}

.p-spirit__swiper .swiper-slide {
  position: relative;
}

.p-spirit__swiper .swiper-slide:before, .p-video video {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.p-spirit__swiper .swiper-slide:before {
  content: '';
  display: block;
  background: rgba(0, 0, 0, .4);
  transition: opacity .7s;
}

.p-spirit__swiper .swiper-slide-active:before {
  opacity: 0;
}

.p-spirit__athlete__eyecatch {
  margin: 0 -1.25rem;
}

.p-spirit__athlete__eyecatch .c-iv-i:before {
  padding: 0 0 40%;
}

.p-spirit__athlete .c-tabs {
  margin-bottom: 2.6875rem;
}

.p-stockist__footer {
  padding: 3.125rem 0 0;
}

.p-user-policy__footer {
  margin-top: 4.975rem;
}

.p-video {
  background: #000;
  position: relative;
  width: 100%;
}

.p-video__inner {
  overflow: hidden;
}

.p-video video {
  min-width: 100%;
  object-fit: cover;
}

.p-video video, _:-ms-lang(x)::-ms-backdrop {
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.p-vision-lab__home {
  padding: 2.375rem 0 7.5rem;
}

.p-vision-lab__home .c-heading--1 {
  margin: 0 0 2.175rem;
}

.p-vision-lab__home__inner {
  padding: 2.5rem 1.25rem;
}

.p-vision-lab__li__grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr;
  grid-template: 1fr/1fr;
  position: relative;
}

.p-vision-lab__li__fig, .p-vision-lab__li__ly, .p-vision-lab__li__text {
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
      grid-row: 1/2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
      grid-column: 1/2;
  position: relative;
}

.p-vision-lab__li__ly {
  z-index: 1;
  transition: background-color .74s;
  background: rgba(0, 0, 0, .7);
  color: #fff;
  flex-flow: column;
}

.p-vision-lab__li__grid:hover .p-vision-lab__li__ly {
  background: rgba(0, 0, 0, .33);
}

.p-vision-lab__li__fig, .p-vision-lab__li__text {
  z-index: 0;
}

.p-vision-lab__li__fig:before {
  padding: 0 0 78.94736842105263%;
}

.p-vision-lab__li__fig img {
  transition: transform .74s;
}

.p-vision-lab__li__text {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.p-vision-lab__li__text__wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr;
  grid-template: 1fr/1fr;
}

.p-vision-lab__li__text--num {
  font-style: italic;
  font-size: 5.125rem;
  line-height: 1.5;
  transform: translate(-2%, 0);
}

.p-vision-lab__li__text--label {
  z-index: 0;
  opacity: .45;
  font-size: 2.1875rem;
  line-height: 1.8;
}

.p-vision-lab__li__view-detail {
  position: absolute;
  bottom: .9375rem;
  right: .9375rem;
  display: flex;
  align-items: center;
  font-size: .75rem;
}

.p-vision-lab__li__view-detail:after {
  content: '';
  width: .9375rem;
  height: .6875rem;
  margin: 0 0 0 .75rem;
  background: url(/assets/ui/arrow-right.w.svg) no-repeat 50% 50%;
  background-size: contain;
}

.p-vision-lab__price {
  margin: 5rem 0 0;
}

.p-vision-lab__price img {
  display: block;
  width: 100%;
  height: auto;
}

.p-vision-lab__swiper {
  position: relative;
}

.p-vision-lab__swiper__pagination {
  position: absolute;
  bottom: 1.25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.p-vision-lab__swiper__pagination .swiper-pagination-bullet {
  width: .875rem;
  height: .875rem;
  padding: .375rem;
  margin: 0;
  background: 0 0;
  border-radius: 0;
  outline: 0;
  opacity: 1;
}

.p-vision-lab__swiper__pagination .swiper-pagination-bullet:before {
  content: '';
  display: block;
  width: .375rem;
  height: .375rem;
  background: #f5f5f2;
  transition: background-color .5s;
  border-radius: 50%;
}

.p-vision-lab__swiper__pagination .swiper-pagination-bullet-active:before {
  background: #a7a7a7;
}

.p-vision-lab__swiper__slide:before {
  padding: 0 0 45%;
}

.p-vision-lab__swiper__video__wrapper {
  position: relative;
  background: #000;
}

.p-vision-lab__swiper__video__wrapper:before {
  display: block;
  content: '';
  width: 100%;
  height: 0;
  padding: 0 0 45%;
}

.p-vision-lab__swiper__video__wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.p-vision-lab__swiper .swiper-slide {
  position: relative;
}

.p-vision-lab__swiper .swiper-slide:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, .4);
  transition: opacity .7s;
  pointer-events: none;
}

.p-vision-lab__swiper .swiper-slide-active:before {
  opacity: 0;
}

.p-vision-lab__heading {
  text-align: center;
  margin: 0 0 2.8rem;
}

.p-vision-lab__media {
  margin: 0 0 2.175rem;
}

.p-vision-lab .c-row img, .p-vision-lab__media img, .p-vision-lab__media video {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.p-vision-lab .c-rows {
  margin: 5rem 0;
}

.p-vision-lab__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid #dddddb;
  border-width: 1px 0;
  padding: 2.175rem 0;
  font-size: .625rem;
  line-height: 1.416666666666667;
}

.p-vision-lab__footer__ln {
  display: block;
  padding: 0 0 0 2.916666666666667em;
  background: url(/assets/ui/arrow-left.svg) no-repeat top 50% left 0;
  background-size: 1.25em;
  transition: opacity .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.p-vision-lab__footer__ln:hover {
  opacity: .7;
}

.p-vision-lab__footer__ln--right {
  padding: 0 2.916666666666667em 0 0;
  background: url(/assets/ui/arrow-right.svg) no-repeat top 50% right 0;
  background-size: 1.25em;
}

.p-vision-lab__footer__ln p {
  margin: 0 0 .666666666666667em;
}

.p-vision-lab__footer__ln p+p {
  margin: 0;
  font-size: 1.5em;
  line-height: .666666666666667;
}

@media only screen and (min-width:641px) {
  html {
    font-size: 2.857142857142857vw;
  }

  .u-hide--m {
    display: initial;
  }
}

@media only screen and (min-width:768px) {
  html {
    font-size: 1.666666666666667vw;
  }

  .c-fns, .c-stockist {
    display: flex;
    margin: 0;
  }

  .c-fns__inner, .c-stockist__inner {
    padding: 1.875rem 1.875rem 1.55rem;
    flex: 1 1;
  }

  .c-fns__ln, .c-stockist__ln {
    width: 9.5rem;
    border-left: 1px solid #dddddb;
    padding: 0;
  }

  .l-content {
    padding: 3rem 1.25rem 10rem;
    max-width: 840px;
    margin: 0 auto;
  }

  .l-content--wide {
    max-width: 1320px;
  }

  .c-button {
    width: 22.1875rem;
    height: 5rem;
    margin: 0 auto;
  }

  .c-column {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-flow: wrap;
  }

  .c-column--3 .c-column__item {
    width: calc((100% - 2.75rem)/3);
    margin: 0 0 0 1.375rem;
  }

  .c-column--3 .c-column__item:nth-child(3n+1) {
    margin-left: 0;
  }

  .c-column--2 .c-column__item {
    width: calc((100% - 2.5rem)/2);
  }

  .c-column--2 .c-column__item:nth-child(n+3) {
    margin-top: 3.75rem;
  }

  .c-fmt--a__ly {
    width: 87.5%;
  }

  .c-fmt--a__tx {
    padding: 4.375rem;
    margin-top: -13.750000000000002%;
  }

  .c-heading--1 {
    font-size: 2.5rem;
    line-height: 1.5;
    margin: 0 0 5.925rem;
  }

  .c-heading--2, .c-heading--2n {
    font-size: 1.375rem;
    line-height: 1.063636363636363;
    margin: 4.81875rem 0 3.58125rem;
  }

  .c-heading--3 {
    font-size: 1.125rem;
    line-height: 1.3;
    font-weight: 600;
    margin: 3.58125rem 0 1.70625rem;
  }

  .c-ln--fig__tx {
    margin: .925rem 0 0;
  }

  .c-ol__heading:before {
    margin: 0 1.25rem 0 0;
  }

  .c-ol__heading__inner {
    font-size: 1.125rem;
    line-height: 1.5;
  }

  .c-ol--slim li {
    align-items: center;
  }

  .c-ol--slim li:before, .c-ol__heading:before {
    width: 2.375rem;
    height: 2.25rem;
  }

  .c-page__heading {
    font-size: 2.5rem;
    line-height: 1.5;
    margin: 0 0 6.25rem;
  }

  .c-page__h2 {
    font-size: 1.25rem;
    line-height: 1.6;
    margin: 3.05rem 0 2.5rem;
    padding: 0 0 1.125rem;
  }

  .c-rows {
    flex-flow: wrap;
  }

  .c-rows--reverse {
    flex-flow: wrap-reverse;
  }

  .c-row {
    width: calc((100% - 2.5rem)/2);
  }

  .c-row--r {
    margin: 0 0 0 2.5rem;
  }

  .c-athlete dl, .c-table {
    display: flex;
    flex-flow: wrap;
  }

  .c-table__td, .c-table__th {
    padding: 1.55rem 1.25rem;
  }

  .c-table__th {
    width: 26.25%;
  }

  .c-table__td {
    width: 73.75%;
  }

  .c-column .c-table__th {
    padding-left: 0;
    width: 42.10526315789473%;
  }

  .c-column .c-table__td {
    width: 57.89473684210527%;
  }

  .c-table--s {
    max-width: 360px;
  }

  .c-table--s .c-table__td, .c-table--s .c-table__th {
    padding: .925rem .625rem;
  }

  .c-table--s .c-table__th {
    width: 44.44444444444444%;
  }

  .c-table--s .c-table__td {
    width: 55.55555555555556%;
  }

  .c-toggle__hd {
    padding: 1.175rem 4.375rem 1.175rem 1.875rem;
  }

  .c-toggle__inner {
    padding: .925rem 1.25rem 1.55rem;
  }

  .c-article__nav {
    font-size: .8125rem;
    padding: 1.875rem 0;
  }

  .c-athlete__li {
    width: 30%;
    margin: 0 0 0 5%;
  }

  .c-athlete__li:nth-child(n+4) {
    margin-top: 5%;
  }

  .c-athlete__li:nth-child(3n+1) {
    margin-left: 0;
  }

  .c-athlete__list {
    padding: 2.175rem 2.5rem;
  }

  .c-athlete dl dt {
    width: 5rem;
  }

  .c-athlete dl dd {
    width: calc(100% - 5rem);
  }

  .c-b2t {
    right: 2.875rem;
    bottom: 2.875rem;
  }

  .c-b2t img {
    width: 4rem;
    height: 4rem;
  }

  .c-breadcrumbs__inner {
    margin: 0 auto;
    max-width: 1320px;
  }

  .c-collections {
    margin: 0 auto;
    max-width: 50rem;
  }

  .c-collections__inner {
    margin: -7.5% 0 0 -2.75%;
  }

  .c-collections__li {
    width: 30.656934306569344%;
    font-size: .8125rem;
    line-height: 1.153846153846154;
    margin: 7.2992700729927% 0 0 2.67639902676399%;
  }

  .c-collections__li p br {
    display: none;
  }

  .c-collections__view-all {
    margin: 5rem 0 0;
  }

  .c-content__inner {
    padding: 6.25rem 1.25rem;
    max-width: 840px;
    margin: 0 auto;
  }

  .c-form__tr {
    display: flex;
    flex-flow: wrap;
    padding: 2.8rem 1.25rem;
  }

  .c-form__th {
    width: 29.47368421052631%;
    padding: 0 4.210526315789473% 0 0;
  }

  .c-form__td {
    width: 67%;
    margin: 0;
    padding: 0 .001644736842105rem 0 0;
  }

  .c-form__td .c-input {
    margin: -1.25rem 0;
  }

  .c-form__td:nth-child(n+3), .c-form__th:nth-child(n+3) {
    margin-top: 2.9125rem;
  }

  .c-form__terms {
    height: 17.1875rem;
  }

  .c-form__footer--confirm {
    flex-flow: wrap row-reverse;
    justify-content: space-between;
  }

  .c-headline__inner {
    padding-left: 2.8125rem;
  }

  .c-information__wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 2.6875rem;
    max-width: 80rem;
  }

  .c-information__li {
    width: 31.25%;
  }

  .c-input {
    max-width: 21.25rem;
    padding: .8625rem 1.25rem;
  }

  textarea.c-input {
    max-width: none;
  }

  select.c-input {
    max-width: 100%;
    width: auto;
    padding-right: 2.375rem;
  }

  .c-input--zip {
    width: 6.5625rem;
  }

  .c-journal__wrapper {
    justify-content: space-between;
    flex-flow: nowrap;
    margin: 0 auto 3.75rem;
    max-width: 80rem;
  }

  .c-journal__li {
    width: 23.828125%;
    font-size: .8125rem;
    line-height: 1.153846153846154;
  }

  .c-lens-c__header__num {
    width: 3.25rem;
    margin: 0 2.1875rem 0 0;
    align-items: center;
  }

  .c-lens-c__header__column {
    display: flex;
  }

  .c-lens-c__header__column__i {
    width: 40.67321178120617%;
    margin: 0 0 0 4.207573632538569%;
  }

  .c-lens-c__inner {
    margin: 0;
  }

  .c-lens-c__fig {
    padding: 3.75rem;
  }

  .c-lens-c__fig--tbl, .c-lens-c__toggle .c-toggle__inner {
    padding: 2.5rem;
  }

  .c-lens-c__column {
    display: flex;
  }

  .c-lens-c__column__r {
    padding: 0;
    width: 30.555555555555557%;
    margin: 0 0 0 4.166666666666666%;
  }

  .c-pagination__el {
    width: 1.875rem;
    height: 1.875rem;
    margin: .25rem;
  }

  .c-shop-li__wrapper {
    padding: 0;
  }

  .c-shop-li {
    display: flex;
    color: #000;
  }

  .c-shop-li__area, .c-shop-li__name {
    margin: 0 5.296610169491525% 0 0;
    width: 26.906779661016948%;
  }

  .c-shop-li__area {
    width: 7.415254237288135%;
  }

  .c-shop-li__desc, .c-shop-li__items {
    margin: 0 5.296610169491525% 0 0;
    width: 17.478813559322035%;
  }

  .c-shop-li__items {
    width: 11.652542372881355%;
  }

  .c-shop-li__ln {
    margin: 0;
    padding: 0;
    width: 15.360169491525424%;
  }

  .c-sz {
    display: none;
  }

  .c-tabs {
    flex-flow: nowrap;
    flex-shrink: 1;
  }

  .c-tabs li {
    width: auto;
    flex: 1 1;
  }

  .c-tabs a {
    padding: 1.6875rem 0;
  }

  .u-hide {
    display: none;
  }

  .p-about-fnm__logo {
    width: 21.25rem;
  }

  .p-about-fnm .c-heading--1 {
    margin: 0 0 3.425rem;
  }

  .p-about-fnm__lead {
    text-align: center;
    margin: 0 0 2.3rem;
  }

  .p-about-fnm__what__header {
    font-size: 1.375rem;
    line-height: 1.5;
    padding: 3.40625rem 0 8.40625rem;
    margin: 0 0 -5.625rem;
  }

  .p-about-fnm__register {
    border-radius: 1.25rem;
    padding: 3.425rem;
    margin: 0;
  }

  .p-about-fnm__register__heading {
    font-size: 1.375rem;
    line-height: 1.5;
    margin: 0 0 2.9375rem;
  }

  .p-about-fnm__register__inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    max-width: 820px;
  }

  .p-about-fnm__register__item {
    width: calc(50% - .625rem);
    max-width: 400px;
  }

  .p-about-fnm__faq {
    margin: 0;
  }

  .p-about-fnm__li {
    padding: 2.175rem 2.5rem;
  }

  .p-about-fnm__li header {
    font-size: 1rem;
    margin: 0 -2.5rem .85rem;
  }

  .p-about-fnm__benefit .p-about-fnm__li header {
    margin: 0 0 .85rem;
  }

  .p-about-fnm__connect__wrapper {
    display: flex;
    justify-content: space-between;
  }

  .p-about-fnm__connect__r {
    width: 53.5483870967742%;
  }

  .p-about-fnm__connect__l {
    margin: 0;
    width: 41.612903225806456%;
  }

  .p-about-fnm__connect .c-pic {
    width: 100%;
  }

  .p-about__ingot__tx {
    margin-top: -33.75%;
  }

  .p-about__banner {
    margin: 7.5rem 0 0;
  }

  .p-about__banner__inner {
    padding: 6.875000000000001% 6.875000000000001% 6.25% 62.5%;
  }

  .p-about__lineup__heading {
    margin: 0 0 2.5rem;
  }

  .p-lens__header__inner {
    height: 16.25rem;
  }

  .p-lens__header__inner--index {
    align-items: flex-end;
  }

  .p-lens__concept__fmt .c-fmt--a__fig {
    width: 70%;
  }

  .p-lens__concept__fmt .c-fmt--a__tx {
    width: 78.25%;
  }

  .p-lens__concept__fig--0 {
    width: 93.38235294117648%;
  }

  .p-lens__concept__fig--2 {
    width: 63.52941176470588%;
  }

  .p-lens__single-sight__fig--0 {
    width: 93.38235294117648%;
    margin: 0 auto;
  }

  .p-lens__single-sight .c-column--3 .c-column__item {
    width: 30.392156862745097%;
  }

  .p-lens__multi-sight__fmt .c-fmt--a__fig {
    width: 70%;
  }

  .p-lens__multi-sight__fmt .c-fmt--a__tx {
    width: 78.25%;
  }

  .p-collection__li+.p-collection__li {
    margin-top: 7.175rem;
  }

  .p-collection__li__label {
    font-size: 1.375rem;
    line-height: 1.063636363636363;
    margin: 3.75rem 0 1.55rem;
  }

  .p-collection__header {
    padding: 2.5rem 3.125rem;
    max-width: 1920px;
    margin: 0 auto;
  }

  .p-collection__inner {
    padding: 4.95625rem 1.25rem 7.5rem;
    max-width: 840px;
    margin: 0 auto;
  }

  .p-collection__other__inner {
    padding: 7.45625rem 1.25rem 6.25rem;
  }

  .p-collection__stockist__inner {
    padding: 6.20625rem 1.25rem 6.25rem;
  }

  .p-collection__stockist__tx {
    text-align: center;
    margin: 0 auto;
  }

  .p-collection-archive__footer {
    margin: 6.25rem 0 0;
  }

  .p-company__office {
    display: flex;
  }

  .p-company__map {
    width: 47.5%;
  }

  .p-company__data {
    width: 52.5%;
    padding: 0 2.5rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .p-company__google-map {
    text-align: left;
  }

  .p-contact__tel {
    height: 12.8125rem;
  }

  .p-contact__tel p:last-child {
    font-size: inherit;
  }

  .p-faq__tabs {
    flex-flow: wrap;
  }

  .p-faq__tabs li {
    width: 20%;
    flex: inherit;
  }

  .g-footer {
    padding: 4.9375rem 0 4.125rem;
  }

  .g-footer__contents, .g-footer__sns {
    border: 0;
    width: auto;
    justify-content: center;
    align-items: center;
  }

  .g-footer__contents {
    flex-flow: row nowrap;
    padding: 0;
  }

  .g-footer__contents li {
    width: auto;
    padding: 0;
  }

  .g-footer__contents li:nth-child(n+2) {
    margin: 0 0 0 1.875rem;
  }

  .g-footer__contents--1 {
    flex-flow: row;
    padding: 5rem 0 2.6875rem;
  }

  .g-footer__contents--2 {
    flex-flow: row;
  }

  .g-footer__contents--1 li:nth-child(n+2) {
    margin: 0 0 0 2.5rem;
  }

  .g-footer__contents--2 {
    padding: 0 0 1.6125rem;
  }

  .g-footer__contents--2 li:nth-child(n+2),
  .g-footer__contents--3 li:nth-child(n+2) {
    margin: 0 0 0 1.875rem;
  }

  .g-footer__sns {
    padding: 3.425rem 0 4.625rem;
  }

  .g-footer__sns a {
    width: 1.4375rem;
  }

  .g-footer__sns a+a {
    margin: 0 0 0 .875rem;
  }

  .g-footer__c {
    margin: 1.375rem 0 0;
  }

  .g-header {
    height: 6.625rem;
  }

  .g-header__inner {
    padding: 1.68rem 1.25rem;
  }

  .g-header .c-logo {
    width: 5.565625rem;
  }

  .g-header__menu {
    top: 1.8125rem;
    left: 2.5rem;
  }

  .g-header__menu__btn {
    width: 2.875rem;
  }

  .g-header__ln__wrapper {
    right: 3.75rem;
    top: 2.7875rem;
  }

  .g-header__ln {
    margin: 0 0 0 2.4375rem;
  }

  .g-header__ln span {
    display: inline-block;
    margin: 0 0 0 .625rem;
    font-size: .8125rem;
    line-height: 1.769230769230769;
  }

  .g-header__lng {
    display: block;
  }

  .g-members {
    width: 19rem;
  }

  .g-members__inner {
    padding: 2.8125rem 3.125rem 3.75rem 3.75rem;
  }

  .g-members__header {
    margin: 0 0 2.85rem;
  }

  .g-members__header p {
    padding: 0 1.5625rem 0 0;
  }

  .g-members__logo {
    margin: 0 -3.125rem 2.25rem -3.75rem;
    padding: 1.6875rem 3.75rem 1.625rem;
  }

  .g-members__li {
    font-size: 1rem;
    line-height: 1.5;
  }

  .g-members__li+.g-members__li {
    margin-top: 1.0625rem;
  }

  .g-menu {
    width: 34rem;
    transition: width .7s;
  }

  .g-menu.is-expand {
    width: 52.125rem;
  }

  .g-menu__inner {
    display: flex;
    flex-flow: column;
    padding: 8rem 3.5rem 8rem 3.75rem;
  }

  .g-menu__inner__t {
    flex: 1 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .g-menu__inner hr {
    margin: .78125rem 0;
  }

  .g-menu__li {
    font-size: 1rem;
    line-height: 1.125;
    padding: .71875rem 0;
  }

  .g-menu__expand {
    position: absolute;
    left: 21.625rem;
    top: 0;
    width: 0;
    height: 100%;
    transition: none;
  }

  .g-menu__expand__inner {
    display: none;
  }

  .g-menu__expand--desktop {
    overflow: hidden;
    transition: width .7s;
    height: 100%;
    display: block;
    background: #f5f5f2;
  }

  .is-expand .g-menu__expand--desktop {
    width: 18.125rem;
  }

  .g-menu__expand--desktop__el {
    flex-flow: column;
    justify-content: center;
    width: 18.125rem;
    padding: 3.75rem;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    white-space: nowrap;
    display: none;
    border-left: 1px solid #dddddb;
  }

  .g-menu__expand--desktop__el.is-expand {
    display: flex;
  }

  .g-menu__sns {
    margin: 1.90625rem 0 0 .25rem;
  }

  .g-menu__sns a+a {
    margin: 0 0 0 1.125rem;
  }

  .g-menu__logo {
    display: block;
  }

  .g-menu__close {
    background: #fff;
    width: 12.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .g-menu__lng, .p-home__about h3 br {
    display: none;
  }

  .p-home__heading--1 {
    font-size: 2rem;
    line-height: 1.03125;
    margin: 0 0 3.75rem;
    letter-spacing: .05em;
  }

  .p-home__heading--2 {
    font-size: 1.5rem;
    line-height: 1.208333333333333;
    letter-spacing: .05em;
  }

  .is-vs-0 .p-home__intro__bar, .is-vs-1 .p-home__intro__bar {
    animation-duration: .73s;
  }

  .p-home__intro__ly--0 .c-logo {
    width: 12.100000000000001rem;
  }

  .is-vs-0 .p-home__intro__mk {
    animation-duration: .73s;
  }

  .p-home__intro__mk .c-logo {
    width: 12.100000000000001rem;
  }

  .p-home__swiper {
    padding: 0;
  }

  .p-home__swiper__image {
    height: 100vh;
  }

  .p-home__main:before, .p-home__swiper__image:before {
    content: none;
  }

  .p-home__swiper__navigation {
    display: block;
  }

  .p-home__swiper__pagination {
    bottom: 8.75rem;
  }

  .p-home__main {
    margin-top: -6.625rem;
    height: 100vh;
  }

  .p-home__about__wrapper {
    margin: -3.75rem 2.5rem 0;
    padding: 5rem 2.5rem 4.0625rem;
    border-radius: .625rem;
  }

  .p-home__about__inner {
    position: relative;
    display: flex;
    max-width: 920px;
    margin: 0 auto;
  }

  .p-home__about .p-home__heading--2 {
    margin: 0;
    text-align: left;
    width: 40.21739130434783%;
  }

  .p-home__about__text {
    font-size: .9375rem;
    width: 59.78260869565217%;
  }

  .p-home__about h3 {
    font-size: 1.5rem;
    line-height: 1.708333333333333;
    margin: -.25rem 0 1.65625rem;
    text-align: left;
  }

  .p-home__about footer {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0;
  }

  .p-home__lineup {
    padding: 10.75rem 1.25rem 3.125rem;
  }

  .p-home__lineup .p-home__heading--1 {
    font-size: 2rem;
    line-height: 1.03125;
    margin: 0 0 -1.3125rem;
  }

  .p-home__lineup__wrapper {
    max-width: 1840px;
    padding: 7.0625rem 0 5rem;
    margin: 0 auto;
  }

  .p-home__lineup__sct {
    padding: 0 5.434782608695652%;
  }

  .p-home__lineup__sct--material .p-home__lineup__sct__inner {
    justify-content: center;
    margin: 0 0 0 -.304878048780488%;
  }

  .p-home__lineup__sct--material .p-home__lineup__a {
    padding: 1.945288753799392% 0 1.276595744680851%;
    margin: 0 0 0 .303951367781155%;
    width: 13.98176291793313%;
  }

  .p-home__lineup__sct--shape .p-home__lineup__sct__inner {
    margin: -.304878048780488% 0 0 -.304878048780488%;
    justify-content: center;
  }

  .p-home__lineup__sct--shape .p-home__lineup__a {
    height: 4.0625rem;
    margin: .303951367781155% 0 0 .303951367781155%;
    width: 19.696048632218847%;
    padding: 0 0 0 1.762917933130699%;
  }

  .p-home__lineup__sct--shape .p-home__lineup__a p {
    width: 64.81481481481481%;
    padding: 0 0 0 6.172839506172839%;
  }

  .p-home__lineup__a {
    font-size: .75rem;
  }

  .p-home__lineup__a__i {
    width: 73.91304347826086%;
    margin: 0 0 2.173913043478261%;
  }

  .p-home__products {
    padding: 3.125rem 1.25rem 10rem;
  }

  .p-home__products .p-home__heading--1 {
    font-size: 2rem;
    line-height: 1.03125;
    margin: 0 0 -1.375rem;
  }

  .p-home__products__inner {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
  }

  .p-home__products__li {
    font-size: .851063829787234vw;
    line-height: 1.6875;
    width: 33.333333333333336%;
  }

  .p-home__products__li .c-iv-i:before {
    padding: 0 0 106.03588907014682%;
  }

  .p-home__products__li .c-iv-i__ly {
    padding: 14.67391304347826% 0 13.695652173913043%;
  }

  .p-home__products__li b {
    line-height: 1.125;
    margin: 0 0 .53125rem;
  }

  .p-home__products__li--9999 {
    width: 100%;
  }

  .p-home__products__li--9999 .c-iv-i:before {
    padding: 0 0 35.32608695652174%;
  }

  .p-home__products__li--9999 .c-iv-i__ly {
    padding: 11.793478260869565% 0 0;
    justify-content: flex-start;
    width: 38.80434782608695%;
  }

  .p-home__products__li--9999 .p-home__products__logo {
    width: 28.011204481792717%;
    margin-top: 5.602240896358544%;
  }

  .p-home__products__li--feelsun .p-home__products__logo {
    width: 57.065217391304344%;
  }

  .p-home__products__li--plaide .c-iv-i__ly {
    padding-bottom: 16.793478260869566%;
  }

  .p-home__products__li--plaide .p-home__products__logo {
    width: 39.13043478260869%;
  }

  .p-home__products__li--worke .c-iv-i__ly {
    padding-bottom: 16.793478260869566%;
  }

  .p-home__products__li--worke .p-home__products__logo {
    width: 39.13043478260869%;
  }

  .p-home__ec {
    padding: 0 1.25rem 3.125rem;
  }

  .p-home__ec .c-iv-i:before {
    padding: 0 0 29.293478260869566%;
  }

  .p-home__ec__inner {
    background-position: top 50% right 5.25rem;
    background-size: 6rem;
  }

  .p-home__ec .p-home__heading--2 {
    font-size: 1.5rem;
    line-height: 1.208333333333333;
  }

  .p-home__collections {
    padding: 6.25rem 2.5rem 3.75rem;
  }

  .p-home__collections .p-home__heading--1 {
    margin: 0 0 3.75rem;
  }

  .p-home__collections__wrapper {
    display: flex;
    flex-flow: wrap;
    margin: 0 auto 3.75rem;
    max-width: 80rem;
  }

  .p-home__collections__li {
    width: 32.1875%;
    font-size: .8125rem;
    line-height: 1.153846153846154;
    margin: 0 0 0 1.71875%;
  }

  .p-home__collections__li:first-child {
    margin-left: 0;
  }

  .p-home__journal {
    padding: 6.25rem 2.5rem;
  }

  .p-home__journal .p-home__heading--1 {
    margin: 0 0 3.75rem;
  }

  .p-home__support {
    padding: 5rem 3.75rem 3.75rem;
  }

  .p-home__support__heading {
    font-size: 1.5625rem;
    line-height: 1.72;
    margin: 0 0 3.1875rem;
  }

  .p-home__support__inner {
    display: flex;
    justify-content: center;
    max-width: 1280px;
    margin: 0 auto;
  }

  .p-home__support__button {
    width: 49.21875%;
    height: auto;
  }

  .p-home__support__button+.p-home__support__button {
    margin: 0 0 0 1.25rem;
  }

  .p-home__support__button__i {
    width: 26.190476190476193%;
    padding: 0 0 18.88888888888889%;
    margin: 0 5.396825396825397% 0 0;
  }

  .p-home__support__button__i--0 {
    background-image: url(/assets/home/support/0.l.png);
  }

  .p-home__support__button__i--1 {
    background-image: url(/assets/home/support/1.l.png);
  }

  .p-home__information {
    padding: 6.25rem 2.5rem 10rem;
  }

  .p-home__information .p-home__heading--2, .p-home__shop .p-home__heading--2 {
    margin: 0 0 3.65625rem;
  }

  .p-home__shop {
    padding: 0 0 7.5rem;
  }

  .p-home__shop .c-iv-i:before {
    padding: 0 0 30.434782608695656%;
  }

  .p-home__shop__buttons {
    display: flex;
    justify-content: center;
  }

  .p-home__shop__buttons p:nth-child(2) {
    margin: 0 0 0 1.25rem;
  }

  .p-informations .c-heading--1 {
    margin: 0 0 4.375rem;
  }

  .p-store-top__li+.p-store-top__li {
    margin-top: 7.175rem;
  }

  .p-store-top__li__label {
    font-size: 1.375rem;
    line-height: 1.063636363636363;
    margin: 3.75rem 0 1.55rem;
  }

  .p-store-top__footer {
    padding: 3.75rem 0;
  }

  .p-store-top__footer__inner {
    display: flex;
    justify-content: center;
  }

  .p-store-top__footer .c-button {
    width: 16.25rem;
  }

  .p-store-top__footer .c-button+.c-button {
    margin: 0 0 0 .625rem;
  }

  .p-store-top__swiper {
    margin: 0 0 7.5rem;
  }

  .p-store-top__swiper .c-pic:before {
    padding: 0 0 36.904761904761905%;
  }

  .p-store-top__swiper__pagination {
    bottom: 1.5rem;
  }

  .p-store-guide__payment .c-row--l {
    width: 78.75%;
  }

  .p-store-guide__payment .c-row--r {
    width: 17.5%;
    margin: 0 0 0 3.75%;
  }

  .p-store-guide__size {
    padding: 1.875rem;
  }

  .p-store-guide__size .c-row--l {
    width: 40.54054054054054%;
  }

  .p-store-guide__size .c-row--r {
    width: 53.37837837837838%;
    margin: 0 0 0 6.081081081081082%;
  }

  .p-parts-and-size__fig {
    padding: 1.3125rem 4.6875rem 6.25rem;
  }

  .p-press-archive .c-heading--1 {
    margin: 0 0 4.375rem;
  }

  .p-press-archive__li {
    width: 30%;
    margin: 0 0 0 5%;
  }

  .p-press-archive__li:nth-child(n+4) {
    margin-top: 5%;
  }

  .p-press-archive__li:nth-child(3n+1) {
    margin-left: 0;
  }

  .p-reserve-day__header {
    height: 29.25rem;
  }

  .p-reserve-day__header__inner {
    width: 24rem;
    height: 14.5rem;
  }

  .p-reserve-day__reserve__inner {
    padding: 2.28125rem 0;
  }

  .p-reserve-day__reserve__btn {
    margin-top: 2.34375rem;
  }

  .p-reserve-day__reserve__footer {
    margin: 4.675rem 0 0;
  }

  .p-reserve-day__scene {
    display: flex;
    align-items: flex-end;
  }

  .p-reserve-day__scene--reverse {
    flex-flow: row-reverse;
  }

  .p-reserve-day__scene__i {
    width: 35%;
    margin: 0 0 7.5%;
  }

  .p-reserve-day__scene__t {
    padding: 12.5% 6.25% 12.5% 11.25%;
    margin: 0 0 0 -5%;
  }

  .p-reserve-day__scene--reverse .p-reserve-day__scene__t {
    padding: 8.75% 11.25% 12.5% 6.25%;
    margin: 0 -5% 0 0;
  }

  .p-reserve-day__scene__num {
    margin: -33.33333333333333% 0 12.380952380952381%;
  }

  .p-reserve-day__scene__num--1 {
    width: 30.564761904761905%;
  }

  .p-reserve-day__scene__num--2 {
    width: 34.63547619047619%;
  }

  .p-reserve-day__scene__num--3 {
    width: 33.65833333333333%;
  }

  .p-reserve-day__flow {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  .p-reserve-day__flow+.p-reserve-day__flow {
    margin: 4.5rem 0 0;
  }

  .p-reserve-day__flow__i {
    width: 35%;
    margin: 0 0 0 2.5%;
  }

  .p-reserve-day__flow__t {
    width: 57.49999999999999%;
  }

  .p-reserve-day__shop-info {
    padding: 3.125rem;
  }

  .p-reserve-day__shop-info .c-row--l {
    width: 57.14285714285714%;
  }

  .p-reserve-day__shop-info .c-row--r {
    width: 37.142857142857146%;
  }

  .p-reserve-day__footer {
    margin: 6.25rem 0 0;
  }

  .p-service-shop__heading {
    margin: 0 0 3.425rem;
  }

  .p-shop__inner {
    margin: 0;
  }

  .p-shop__aside__banner {
    margin: 1.925rem 0 0;
  }

  .p-shop__loc__ui {
    margin: 3.75rem 0 0;
    display: flex;
    justify-content: space-between;
  }

  .p-shop__loc__form {
    width: 48.4375%;
  }

  .p-shop__loc__info {
    width: 48.4375%;
    margin: 0;
  }

  .p-shop__loc__map__wrapper {
    position: relative;
    margin: 3.25rem 0 0;
  }

  .p-shop__loc__map__wrapper .c-map {
    z-index: 0;
  }

  .p-shop__loc__map__wrapper .c-map:before {
    padding: 0 0 62.5%;
  }

  .p-shop__loc__map__info {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 25rem;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .p-shop__loc__map__info__inner {
    padding: 3.4375rem 3.125rem;
  }

  .p-shop__loc__map__info__ln {
    display: flex;
    justify-content: space-between;
  }

  .p-shop__loc__map__info__btn {
    width: 9.0625rem;
  }

  .p-shop__loc__map__info__btn+.p-shop__loc__map__info__btn {
    margin: 0;
  }

  .p-shop__loc__map__info__close {
    top: 0;
    background: 0 0;
  }

  .p-shop__list {
    margin: 5rem 0 0;
    display: flex;
  }

  .p-shop__list__side {
    width: 16.875%;
    margin: 0 4.6875% 0 0;
  }

  .p-shop__list__side .c-toggle {
    border: 0;
  }

  .p-shop__list__side .c-toggle__hd {
    display: none;
  }

  .p-shop__list__side .c-toggle__inner {
    display: block !important;
    padding: 0;
  }

  .p-shop__list__side__a {
    display: block;
    width: 100%;
  }

  .p-shop__list__side__a+.p-shop__list__side__a {
    margin-top: 1.55rem;
  }

  .p-shop__list__side__a hr {
    display: block;
    margin: 0 auto 0 0;
    border-color: #000;
  }

  .p-shop__list__side__a__inner {
    text-align: center;
    padding: 0 .375rem .5rem;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .p-shop__list__side__a__inner:after {
    display: block;
    content: '';
    width: .307692307692308em;
    height: .307692307692308em;
    background: #000;
    border-radius: 50%;
  }

  .p-shop__list__side__a:hover hr {
    animation: iv-ln-hr .7s forwards ease-in-out;
  }

  .p-shop__list__inner {
    flex: 1 1;
    margin: 0;
  }

  .p-shop__list__filter, .p-shop__list__filter__products {
    display: flex;
    align-items: center;
  }

  .p-shop__list__filter__products p {
    margin: 0 1rem 0 0;
  }

  .p-shop__list__filter__products select {
    width: 11.875rem;
  }

  .p-shop__list__filter__brands {
    margin: 0 0 0 2.5rem;
  }

  .p-shop__detail__wrapper {
    display: flex;
    align-items: stretch;
  }

  .p-shop__detail__inner {
    padding: 0;
    width: 31.25%;
    margin: 0 3.125% 0 0;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .p-shop__detail .c-heading--1 {
    margin-bottom: 7.5rem;
  }

  .p-shop__detail .p-shop__tag {
    margin: 0 0 .39375rem;
  }

  .p-shop__detail__name {
    font-size: 1.375rem;
    line-height: 1.063636363636363;
  }

  .p-shop__detail__desc {
    margin: 3.01875rem 0 0;
  }

  .p-shop__detail__items, .p-shop__detail__note {
    margin: 1.225rem 0 0;
  }

  .p-shop__detail__map {
    margin: 0;
    width: 65.625%;
  }

  .p-shop__detail__map .c-map {
    min-height: 32.5rem;
    height: 100%;
  }

  .p-shop__detail__map .c-map:before {
    content: none;
  }

  .p-shop__detail__aside {
    margin: 6.25rem 0 0;
  }

  .p-shop__detail__shop-info {
    margin: 6.25rem 0 0;
    display: flex;
    justify-content: space-between;
  }

  .p-shop__detail__shop-info__inner {
    margin: 0;
    width: 47.65625%;
  }

  .p-shop__detail__swiper {
    width: 47.65625%;
  }

  .p-shop__detail__swiper .swiper-container {
    margin: 0;
  }

  .p-shop__detail__footer {
    margin: 6.25rem 0 0;
  }

  .p-shop__fns__header {
    height: 16.25rem;
  }

  .p-shop__fns-list__footer {
    padding: 6.25rem 0 0;
  }

  .p-shop__fns__sct+.p-shop__fns__sct {
    margin: 3.75rem 0 0;
  }

  .p-shop__fns__mark {
    padding: 2.1875rem 1.875rem;
    display: flex;
    align-items: center;
  }

  .p-shop__fns__mark__fig {
    width: 4.625rem;
  }

  .p-shop__fns__mark p {
    margin: 0 0 0 1.0625rem;
  }

  .p-spirit__home {
    padding: 3rem 0 10rem;
  }

  .p-spirit__home .c-heading--1, .p-vision-lab__home .c-heading--1 {
    margin: 0 0 5.8rem;
  }

  .p-spirit__home__inner {
    padding: 5rem 1.25rem;
    max-width: 840px;
    margin: 0 auto;
  }

  .p-spirit__home__nav__inner {
    margin-top: 74.8;
  }

  .p-spirit__home__nav__button {
    width: 100%;
  }

  .p-spirit__swiper__pagination {
    bottom: 1.5rem;
  }

  .p-spirit__athlete__eyecatch {
    margin: 0;
  }

  .p-stockist__footer {
    padding: 6.25rem 0 0;
  }

  .p-video {
    height: 100vh;
  }

  .p-video video {
    min-height: 100%;
  }

  .p-vision-lab__home {
    padding: 3rem 0 10rem;
  }

  .p-vision-lab__home__inner {
    padding: 5rem 1.25rem;
    max-width: 840px;
    margin: 0 auto;
  }

  .p-vision-lab__li__view-detail {
    bottom: 1.875rem;
    right: 1.875rem;
  }

  .p-vision-lab__swiper__pagination {
    bottom: 1.5rem;
  }

  .p-vision-lab__footer {
    font-size: .75rem;
  }
}

@media only screen and (min-width:768px) and (min-height:691px) {
  .g-menu__inner {
    justify-content: center;
  }

  .g-menu__expand--desktop__el, .g-menu__inner {
    overflow: visible;
  }
}

@media only screen and (min-width:841px) {
  .c-ihd {
    margin: 0 calc((-100vw + 800px)/2) 5rem;
  }
}

@media only screen and (min-width:961px) {
  html {
    font-size: 100%;
  }
}

@media only screen and (min-width:1101px) {
  .p-home__about__wrapper {
    margin: -3.75rem 5rem 0;
    padding: 7.5rem 5rem 6.25rem;
  }

  .p-home__lineup__a {
    font-size: .8125rem;
  }

  .p-shop__wrapper .c-heading--1 {
    margin-bottom: -3.125rem;
  }

  .p-shop__tabs {
    margin: 0;
    justify-content: flex-end;
  }
}

@media only screen and (min-width:1321px) {
  .p-about-fnm__what {
    margin: 0 calc(-50vw + 640px);
  }
}

@media only screen and (min-width:1441px) {
  .p-home__swiper__arrow.-next {
    right: 60px;
  }

  .p-home__swiper__arrow.-prev {
    left: 60px;
  }

  .p-home__lineup {
    padding: 10.75rem 2.5rem 3.125rem;
  }

  .p-home__products {
    padding: 3.125rem 2.5rem 10rem;
    max-width: 1920px;
    margin: 0 auto;
  }

  .p-home__ec {
    padding: 0 2.5rem 3.75rem;
  }
}

@media only screen and (min-width:1921px) {
  .p-home__products__li {
    font-size: 1rem;
  }
}

@media only screen and (max-width:767px) {
  .c-column--3 .c-column__item:nth-child(n+2) {
    margin: .625rem 0 0;
  }

  .c-column--2 .c-column__item:nth-child(n+2) {
    margin: 2.5rem 0 0;
  }

  .c-rows--reverse .c-row--l {
    order: 2;
    margin: 1.875rem 0 0;
  }

  .c-rows--reverse .c-row--r {
    order: 1;
    margin: 0;
  }

  .c-table__th:nth-of-type(n+2) {
    margin-top: .625rem;
  }

  .c-table--s .c-table__th:nth-of-type(n+2) {
    margin-top: 0;
  }

  .c-athlete__li {
    width: 47.76119402985074%;
  }

  .c-athlete__li:nth-child(n+3) {
    margin: 5.970149253731343% 0 0;
  }

  .c-athlete__li:nth-child(2n+2) {
    margin-left: 4.477611940298507%;
  }

  .c-athlete dl dt:nth-of-type(n+2) {
    margin: .625rem 0 0;
  }

  .c-collections__li {
    width: 50%;
  }

  .c-collections__li:nth-child(n+3) {
    margin-top: 2.0625rem;
  }

  .c-form__th:nth-child(2n+3) {
    margin-top: 1.85rem;
  }

  .c-form__td, .c-form__th {
    word-break: break-all;
  }

  .c-form__footer--confirm .c-button:nth-child(2) {
    margin-top: 1.25rem;
  }

  .c-journal__li:nth-child(n+3) {
    margin-top: 1.875rem;
  }

  .p-about-fnm__register__item+.p-about-fnm__register__item {
    margin: 2.125rem 0 0;
  }

  .p-home__collections__li:nth-child(n+2) {
    margin-top: 2.0625rem;
  }

  .p-press-archive__li {
    width: 47.76119402985074%;
  }

  .p-press-archive__li:nth-child(n+3) {
    margin: 5.970149253731343% 0 0;
  }

  .p-press-archive__li:nth-child(2n+2) {
    margin-left: 4.477611940298507%;
  }

  .p-reserve-day__scene--reverse .p-reserve-day__scene__i {
    margin: 0 -.625rem 0 auto;
  }

  .p-reserve-day__scene--reverse .p-reserve-day__scene__num {
    margin: -23.728813559322035% auto 6.779661016949152% -.625rem;
  }

  .p-shop__list__side__a {
    width: calc((100% - 1px)/2);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;
  }

  .p-shop__list__side__a:nth-child(2n+2) {
    margin-left: 1px;
  }

  .p-shop__list__side__a:nth-child(n+3) {
    margin-top: 1px;
  }

  .p-spirit__home__nav .c-column__item:nth-child(n+2) {
    margin-top: .75rem;
  }
}

@media only screen and (max-width:320px) {
  html {
    font-size: 87.5%;
  }
}
