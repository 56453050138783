*:focus {
  outline: 0 !important;
}

body {
  background-color:#F5F5F2;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html{overflow-x:hidden; overflow-y:auto;}

a:hover {
  color: inherit;
  text-decoration: none;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding: 0;
}

.contents-title{
padding: 30px 30px 0px 30px;
font-size: 26px;
font-family: "Helvetica Neue", "Arial", "Cormorant Garamond", sans-serif;text-align: center;
letter-spacing: 0px;
color: #000000;
}

.contents-subtitle{
  padding-bottom: 30px;
  font-size: 11px;
  font-family: "Helvetica Neue", "Arial", "Cormorant Garamond", sans-serif;  text-align: center;
  letter-spacing: 0px;
  color: #000000;
}

.card {
  border-radius: 0;
}
.card-header:first-child {
  border-radius: 0;
}
.card-footer:last-child {
  border-radius: 0;
}

.btn {
  border-radius: 0;
  cursor:pointer;
}

.breadcrumb{
  color: #000000;
  background-color: unset;
  font-size: 11px;
}

.list-group {
  padding-left: 12px;
  padding-right: 12px;
}
.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  background-color: inherit;
  border: 0px;
  border-top: 1px solid #7E838C;
  border-bottom: 1px solid #7E838C;
}
.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.modal-content {
  border-radius: 0;
}
.width90 .modal-content {
  top: 30px;
  width: 90%;
  left: 5%;
}

.form-control:focus {
  box-shadow: none;
}
.btn.focus, .btn:focus {
  box-shadow: none;
}

h1 {
  font-size: 19px;
  margin: 25px 0;
  text-align: center;
  font-weight: normal;
}

.favorite {
  font-size: 20px;
  border: 0;
  background-color: transparent;
  padding: 0;
}

.back {
  margin: 10px 20px 0;
  padding: 0;
  cursor:pointer;
}
.back button {
  border: 0;
  padding: 0;
  cursor:pointer;
}
.back button:before {
  content: "";
  position: absolute;
  left: -10px;
  width: 80px;
  height: 50px;
}

.btn {
  border-radius: 4px;
  text-align: center;
  font-size: 13px;  
  border: solid 1px #707070;
  color: #000000;
  background-color: #ffffff;
  text-align: center;
  position: relative;
  cursor:pointer;
}
.btn-large {
  height: 76px;
  width: 335px;
  border-radius: 4px;
  text-align: center;
  font-size: 13px;  
  border: solid 1px #707070;
  color: #ffffff;
  background-color: #000000;
  text-align: center;
  position: relative;
  cursor:pointer;
}
.btn-block {
  margin: 25px;
  width: calc(100% - 50px);
}

.btn-primary {
  border: 0;
  background-color: #000000;
  color: #ffffff;
  cursor:pointer;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).hover, 
.btn-primary:not(:disabled):not(.disabled):hover,
.btn-primary:not(:disabled):not(.disabled).focus, 
.btn-primary:not(:disabled):not(.disabled):focus
{
  background-color: #000000;
  color: #ffffff;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #EFEFEF;
  background-color: #707070;
  border-color: #707070;
}

.btn-secondary:not(:disabled):not(.disabled).active, 
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).hover, 
.btn-secondary:not(:disabled):not(.disabled):hover,
.btn-secondary:not(:disabled):not(.disabled).focus, 
.btn-secondary:not(:disabled):not(.disabled):focus
{
  border: solid 1px #707070;
  color: #000000;
  background-color: #ffffff;
}

.more {
  font-family: EB Garamond;
  text-align: center;
  border-bottom: 1px solid;
  width: 150px;
  margin: auto;
  padding-top: 40px;
  margin-bottom: 40px;
  cursor: pointer;
}

.btn-success {
  border: 0;
  background-color: #6AC0CA;
  color: #ffffff;
  font-size: 19px;
  padding: 14px;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).hover, 
.btn-success:not(:disabled):not(.disabled):hover,
.btn-success:not(:disabled):not(.disabled).focus, 
.btn-success:not(:disabled):not(.disabled):focus
{
  background-color: #6AC0CA;
  color: #ffffff;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: "＞";
}

.breadcrumb-item a {
  cursor: pointer;
}

.breadcrumb-item:last-of-type {
  cursor: unset;
}
